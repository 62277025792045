import { DataGridTypes } from 'devextreme-react/cjs/data-grid'
import { Form as DXForm, GroupItem, SimpleItem, Tab, TabbedItem, TabPanelOptions } from 'devextreme-react/form'
import type { nota_ticket, ticket } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { FileList, MgtTemplateProps } from '@microsoft/mgt-react'
import { LoadIndicator, ScrollView } from 'devextreme-react'
import TicketEditorEvents from '@/routes/tickets/TIcketEditor.events'
import NoteView from '@/routes/tickets/notes/NoteView'
import { StatoTicket } from '@/routes/tickets/TicketEditor.enums'

const TicketBriefDetail = (props: DataGridTypes.MasterDetailTemplateData) => {
  const ticket: ticket = props.data.data
  let path
  if (ticket.attachmentsFolderUrl) {
    path = ticket.attachmentsFolderUrl
  }

  const MyEvent = (props: MgtTemplateProps) => {
    const { template } = props
    switch (template) {
      case 'no-data': {
        return <div>Nessun allegato caricato</div>
      }
      case 'loading': {
        return <LoadIndicator id="small-indicator" height={20} width={20} />
      }
      default: {
        break
      }
    }
  }
  return (
    <div className={'dx-card responsive-paddings'}>
      <DXForm id={'ticket_brief_detail_form'} formData={ticket} labelLocation={'top'} readOnly={true}>
        <GroupItem colCount={5} colSpan={5}>
          <GroupItem colCount={1} colSpan={5}>
            <TabbedItem>
              <TabPanelOptions
                animationEnabled={true}
                swipeEnabled={false}
                deferRendering={false}
                scrollingEnabled={true}
              />
              <Tab title="Note">
                <SimpleItem
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{ readOnly: true }}
                  render={() => {
                    return (
                      <>
                        <ScrollView height={300} width={'100%'} direction="vertical">
                          <div>
                            <div id="chat-window">
                              {ticket?.note_ticket && ticket?.note_ticket?.length > 0
                                ? ticket?.note_ticket
                                    ?.sort((notaA: nota_ticket, notaB: nota_ticket) => {
                                      const dataA: Date = new Date(notaA.data ?? '')
                                      const dataB: Date = new Date(notaB.data ?? '')
                                      return dataB.getTime() - dataA.getTime()
                                    })
                                    .map((nota: nota_ticket) => {
                                      return (
                                        <>
                                          <NoteView note={nota} readOnly={true} visibleEditClick={false}></NoteView>
                                        </>
                                      )
                                    })
                                : 'Nessuna nota associata al ticket'}
                            </div>
                          </div>
                        </ScrollView>
                      </>
                    )
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Motivo">
                <SimpleItem
                  dataField={'descrizione'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    readOnly: true,
                    valueType: 'html',
                  }}
                ></SimpleItem>
              </Tab>
              {ticket?.stato?.id === StatoTicket.COMPLETATO && (
                <Tab title="Soluzione">
                  <SimpleItem
                    dataField={'soluzione'}
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{
                      stylingMode: 'filled',
                      readOnly: true,
                      valueType: 'html',
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              {ticket?.stato?.id === StatoTicket.COMPLETATO && ticket.rating && (
                <Tab title="Rating">
                  <SimpleItem
                    dataField={'rating_note'}
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{
                      stylingMode: 'filled',
                      readOnly: true,
                      valueType: 'html',
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              <Tab title="Eventi">
                <TicketEditorEvents ticket={ticket}></TicketEditorEvents>
              </Tab>
              <Tab title="Allegati">
                <FileList
                  driveId={import.meta.env.VITE_QSADMINSITE_DRIVE_ID}
                  itemPath={path}
                  itemView="twolines"
                  pageSize={3}
                  draggable={true}
                >
                  <MyEvent template="no-data" />
                  <MyEvent template="loading" />
                </FileList>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
      </DXForm>
    </div>
  )
}

export default TicketBriefDetail
