import React, { useCallback, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form, {
  ButtonItem,
  ButtonOptions,
  ColCountByScreen,
  CustomRule,
  EmailRule,
  GroupItem,
  Item,
  Label,
  RequiredRule,
} from 'devextreme-react/form'
import notify from 'devextreme/ui/notify'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import type { ValidationCallbackData } from 'devextreme-react/common'
import './CreateAccountForm.scss'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { useCognitoManager } from '../../auth/aws/cognitoManager'

export default function CreateAccountForm() {
  const navigate = useNavigate()
  const CognitoRegister = useCognitoManager((state) => state.CognitoRegister)
  const [loading, setLoading] = useState(false)
  const formData = useRef({ email: '', password: '' })

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      const { email, password } = formData.current
      setLoading(true)
      const attributes = [] as CognitoUserAttribute[]
      CognitoRegister(email, password, attributes)
        .then((result) => {
          if (result instanceof Error) {
            notify(result.message, 'error', 3000)
          } else {
            navigate('/login')
          }
        })
        .catch((error) => {
          notify(error.message, 'error', 3000)
        })
      setLoading(false)
    },
    [CognitoRegister, navigate],
  )

  const confirmPassword = useCallback(({ value }: ValidationCallbackData) => value === formData.current.password, [])

  return (
    <form className={'create-account-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading} showOptionalMark={true}>
        {/*Hidden fields*/}
        <Item
          dataField={'zoneinfo'}
          visible={false}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
          }}
        >
          <Label visible={false} />
        </Item>
        <Item
          dataField={'locale'}
          visible={false}
          editorType={'dxTextBox'}
          editorOptions={{
            mode: 'text',
          }}
        >
          <Label visible={false} />
        </Item>

        {/*Visible fields*/}

        <GroupItem colCount={1}>
          {/*<ColCountByScreen sm={1} md={2} lg={3} />*/}

          <GroupItem caption={'Personal Information'}>
            <ColCountByScreen sm={1} md={1} lg={1} />
            {/* TODO add this besides the personal information fields */}
            {/*https://js.devexpress.com/Demos/WidgetsGallery/Demo/FileUploader/CustomDropzone/React/Light/*/}
            <GroupItem>
              {/* TODO find out how to set the aspect ratio for this group*/}
              <Item
                dataField={'given_name'}
                editorType={'dxTextBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  placeholder: 'First Name',
                  mode: 'text',
                }}
              >
                <Label visible={false} />
              </Item>
              <Item
                dataField={'family_name'}
                editorType={'dxTextBox'}
                editorOptions={{
                  stylingMode: 'filled',
                  placeholder: 'Last Name',
                  mode: 'text',
                }}
              >
                <Label visible={false} />
              </Item>
            </GroupItem>
          </GroupItem>
          <GroupItem caption={'Company Information'} colCount={1}>
            <Item
              dataField={'website'}
              isRequired={false}
              editorType={'dxTextBox'}
              editorOptions={{
                stylingMode: 'filled',
                placeholder: 'Website',
                mode: 'url',
              }}
            >
              <Label visible={false} />
            </Item>
          </GroupItem>
          <GroupItem caption={'Registration information'} colCount={2}>
            <Item
              dataField={'phone_number'}
              isRequired={false}
              editorType={'dxTextBox'}
              helpText={'Please include your country code'}
              editorOptions={{
                stylingMode: 'filled',
                placeholder: 'Phone',
                mode: 'tel',
              }}
            >
              <Label visible={false} />
            </Item>
            <Item
              dataField={'email'}
              editorType={'dxTextBox'}
              helpText={'Please use your work email address if you have one'}
              editorOptions={{
                stylingMode: 'filled',
                placeholder: 'Email',
                mode: 'email',
              }}
            >
              <RequiredRule message="Email is required" />
              <EmailRule message="Email is invalid" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={'password'}
              editorType={'dxTextBox'}
              editorOptions={{
                stylingMode: 'filled',
                placeholder: 'Password',
                mode: 'password',
              }}
            >
              <RequiredRule message="Password is required" />
              <Label visible={false} />
            </Item>
            <Item
              dataField={'confirmedPassword'}
              editorType={'dxTextBox'}
              editorOptions={{
                stylingMode: 'filled',
                placeholder: 'Confirm Password',
                mode: 'password',
              }}
            >
              <RequiredRule message="Password is required" />
              <CustomRule message={'Passwords do not match'} validationCallback={confirmPassword} />
              <Label visible={false} />
            </Item>
          </GroupItem>
        </GroupItem>
        <Item>
          <div className="policy-info">
            By creating an account, you agree to the <Link to="#">Terms of Service</Link> and{' '}
            <Link to="#">Privacy Policy</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
            <span className="dx-button-text">
              {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Create a new account'}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Have an account? <Link to={'/login'}>Sign In</Link>
          </div>
        </Item>
      </Form>
    </form>
  )
}
