import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ad_product_catalog } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ILineItemRef, LineItemProps } from '@/routes/quotes/line-items/LineItem.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { ValidationCallbackData } from 'devextreme/common'
import DataSource from 'devextreme/data/data_source'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import './LineItem.scss'
import PricingGroupItem from '@/routes/quotes/line-items/PricingGroupItem'

// eslint-disable-next-line react/display-name
const LineItemTrueup = forwardRef<ILineItemRef, LineItemProps>(
  (props: LineItemProps, ref: React.ForwardedRef<ILineItemRef>) => {
    const { item, products, creating, readOnly, removeSelf } = props

    const title = item.quoteLineNumber ? `${item.action} ${item.quoteLineNumber}` : `${item.action}`

    const { service } = useQsAdminApiManager()

    const formRef = useRef<FormRef>(null)

    const [style, setStyle] = useState<string>('line-item')
    const [termDataSource, setTermDataSource] = useState<DataSource>(
      ArraySourceComposer(
        'termCode',
        item && 'offer' in item && item.offer && item.offer.term ? [item.offer.term] : [],
      ),
    )

    const getTerms = async (productCode: string) => {
      const termsData = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringCode.eq(productCode))
        builder.select('termCode', 'termDescription')
        builder.orderBy(product.termCode.asc())
      })
      return termsData.data.value
    }

    //metodi esposti dal componente
    useImperativeHandle(ref, () => {
      return {
        validate() {
          console.log('index item validate', item.lineNumber)
          return formRef.current?.instance().validate()
        },
      } satisfies ILineItemRef
    }, [item.lineNumber])

    return (
      <>
        <div id={`item-quote-container-${item.lineNumber}`} className={style}>
          <DXForm
            id={`item_form_${item.lineNumber}`}
            key={`item_form_${item.lineNumber}`}
            formData={item}
            labelLocation={'top'}
            colCount={1}
            showValidationSummary={true}
            validationGroup={`itemValidation_${item.lineNumber}`}
            ref={formRef}
            readOnly={readOnly}
          >
            <GroupItem colCount={2}>
              <SimpleItem>
                <h3 className="title">{title}</h3>
              </SimpleItem>
              {item.operation === '' || item.operation === 'Insert' ? (
                <ButtonItem>
                  <ButtonOptions
                    disabled={readOnly}
                    text="Elimina"
                    icon={'trash'}
                    stylingMode={'text'}
                    type="danger"
                    onClick={(e) => {
                      removeSelf()
                      setStyle('line-item deleted')
                    }}
                  />
                </ButtonItem>
              ) : (
                <ButtonItem>
                  <ButtonOptions
                    disabled={readOnly}
                    text="Ripristina"
                    icon={'undo'}
                    stylingMode={'text'}
                    type="success"
                    onClick={(e) => {
                      item.operation = ''
                      setStyle('line-item ')
                    }}
                  />
                </ButtonItem>
              )}
            </GroupItem>
            <GroupItem colCount={5}>
              <SimpleItem
                dataField="productCatalog"
                label={{ text: 'Prodotto' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('offeringId', products),
                  placeholder: 'Selezionare il prodotto...',
                  displayExpr: 'offeringName',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il prodotto',
                    hideOnOutsideClick: true,
                  },
                  onValueChanged: (e: ValueChangedEvent) => {
                    if (e.previousValue?.offeringCode === e.value?.offeringCode) return
                    console.log('PRODUCT EVENT CHANGED', e.previousValue, e.value)
                    if (e.value) {
                      getTerms(e.value.offeringCode).then((res: ad_product_catalog[]) => {
                        setTermDataSource(
                          ArraySourceComposer('termCode', [
                            ...new Map(
                              res.map((product: ad_product_catalog) => [product['termCode'], product]),
                            ).values(),
                          ]),
                        )
                        formRef.current?.instance().updateData('offer.term', null)
                      })
                    }
                  },
                }}
              >
                <RequiredRule message="Prodotto obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="offer.term"
                label={{ text: 'Termini' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: termDataSource,
                  placeholder: 'Selezionare il termine...',
                  displayExpr: 'termDescription',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il termine',
                    hideOnOutsideClick: true,
                  },
                }}
              >
                <RequiredRule message="Termini obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="startDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  readOnly: !creating,
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Inizio' }}
              >
                <RequiredRule message="Data Inizio obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="endDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Fine' }}
              >
                <RequiredRule message="Data Fine obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="quantity"
                editorType="dxNumberBox"
                label={{ text: 'Quantità' }}
                editorOptions={{
                  showSpinButtons: true,
                  min: 0,
                }}
              >
                <RequiredRule message="Quantità obbligatoria"></RequiredRule>
                <CustomRule
                  message="Quantità deve essere maggiore di 0"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return value > 0
                  }}
                ></CustomRule>
              </SimpleItem>
            </GroupItem>
            {!creating && item.operation !== 'Insert' && PricingGroupItem()}
          </DXForm>
        </div>
      </>
    )
  },
)

export default LineItemTrueup
