import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import QuoteCreatorRoute from '@/routes/quotes/QuoteCreator.route'
import QuoteEditorRoute from '@/routes/quotes/QuoteEditor.route'
import QuotesPage from '@/routes/quotes/QuotesPage'
import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { ActionFunction, defer, LoaderFunction } from 'react-router-typesafe'

export const quotesPageRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Sales, Roles.ExternalSales],
  })
}) satisfies LoaderFunction

const autoRenewalCreateAction = (async ({ request, params }: RouteFunctionParams) => {
  const client = useQsAdminApiManager.getState().client
  return await client.get('/api/autodeskpws/quotes/autorenewalcreate', {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}) satisfies ActionFunction

export const QuotesPageRoute: RoleRoute = {
  path: 'quotes',
  children: [
    {
      index: true,
      element: <QuotesPage />,
      loader: quotesPageRouteLoader,
      allowedRoles: [Roles.Sales, Roles.Administrator, Roles.GlobalAdministrator, Roles.Marketing],
    } as RoleRouteObject,
    QuoteCreatorRoute,
    QuoteEditorRoute,
    {
      path: 'autorenewalcreate',
      action: autoRenewalCreateAction,
      allowedRoles: [Roles.Sales, Roles.Administrator, Roles.GlobalAdministrator, Roles.Marketing],
    } as RoleRouteObject,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Quote',
  icon: 'money',
  mainMenuRoute: true,
  allowedRoles: [Roles.Sales, Roles.Administrator, Roles.GlobalAdministrator, Roles.Marketing],
}
