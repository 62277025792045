import ODataStore from 'devextreme/data/odata/store'
import DataSource from 'devextreme/data/data_source'
import { memo, useState } from 'react'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import type {
  qs_applicazione,
  qs_licenza,
  qs_versione,
  tipologia_qs_licenza,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import { FormMode } from '@/enums'
import { Column, DataGrid, Editing, Lookup, Paging } from 'devextreme-react/data-grid'
import type { InitNewRowEvent, SavingEvent } from 'devextreme/ui/data_grid'
import useTokenRefresh from '@/auth/azure/azureManager'
import notify from 'devextreme/ui/notify'

type SoftwareQsEditorFormAppsProps = {
  license: qs_licenza
  mode: FormMode
  qsVersions: qs_versione[]
  qsLicenseTypes: tipologia_qs_licenza[]
  qsApps: qs_applicazione[]
  readOnly?: boolean
}

export const SoftwareQsEditorFormApps = (props: SoftwareQsEditorFormAppsProps) => {
  const [currentQsLicenseData] = useState<qs_licenza>(props.license)
  const today = new Date().toISOString()
  const token = useTokenRefresh()

  const qsLicenseApplicationsDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/qs_licenza_applicazione`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['licenza.id', currentQsLicenseData?.id]],
    expand: ['applicazione', 'tipologia_licenza', 'ultima_versione', 'licenza'],
  })

  return (
    <DataGrid
      id={`sw-qsapps-grid-${currentQsLicenseData?.id}`}
      dataSource={qsLicenseApplicationsDataSource}
      keyExpr={'id'}
      showBorders={true}
      showColumnLines={true}
      focusedRowEnabled={true}
      columnHidingEnabled={false}
      allowColumnReordering={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      wordWrapEnabled={false}
      repaintChangesOnly={true}
      width="100%"
      onSaving={(e: SavingEvent) => {
        console.log('SAVINGGG', e)
      }}
      onInitNewRow={(e: InitNewRowEvent) => {
        e.data.aggiornamenti = false
        e.data.passaggio_noleggio = false
        e.data.data_associazione = today
        e.data.licenza = currentQsLicenseData
      }}
    >
      <Paging defaultPageSize={8} />
      <Editing
        mode="batch"
        allowUpdating={props.mode !== FormMode.View && !props.readOnly}
        allowAdding={props.mode !== FormMode.View && !props.readOnly}
        allowDeleting={props.mode !== FormMode.View && !props.readOnly}
        selectTextOnEditStart={true}
        startEditAction={'dblClick'}
        onChangesChange={(value: any) => {
          // Triggerato quando avviene una modifica. Se l'array length è vuoto significa che la modifica è stata annullata o salvata....
          //console.log('CHHHHHH', value)
          //setAppsAreDirty(value.length > 0)
        }}
      />
      <Column dataField={'id'} width={'3%'} dataType={'number'} visible={false} />,
      <Column dataField={'data_associazione'} caption={'DATA'} width={'12%'} dataType={'date'} format={'dd/MM/yyyy'} />
      <Column dataField={'applicazione.id'} caption={'APPLICAZIONE'}>
        <Lookup dataSource={props.qsApps} displayExpr="nome" valueExpr="id" />
      </Column>
      <Column dataField={'tipologia_licenza.id'} caption={'TIPO'}>
        <Lookup dataSource={props.qsLicenseTypes} displayExpr="nome" valueExpr="id" />
      </Column>
      <Column dataField={'ultima_versione.id'} caption={'UV'} width={'5%'}>
        <Lookup dataSource={props.qsVersions} displayExpr="major" valueExpr="id" />
      </Column>
      <Column dataField={'scadenza'} caption={'SCADENZA'} width={'12%'} dataType={'date'} format={'dd/MM/yyyy'} />
      <Column dataField={'passaggio_noleggio'} caption={'SWITCH'} width={'10%'} />
      <Column dataField={'aggiornamenti'} caption={'US'} width={'5%'} />
    </DataGrid>
  )
}

const SoftwareQsAppsGridMemoed = memo(SoftwareQsEditorFormApps, (oldProps, newProps) => {
  return oldProps.license.id === newProps?.license.id
})

export default SoftwareQsAppsGridMemoed
