import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import {
  ButtonItem,
  ButtonOptions,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useRevalidator } from 'react-router-dom'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { azienda, ordine, sede, task, user } from '@/model/qsadminapi/QsAdminApiModuleModel'
import DataSource from 'devextreme/data/data_source'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { TaskEditorFormProps } from '@/routes/tasks/TaskEditor.types'
import { isUserRoleAllowed, toolbarHtmlEditor } from '@/routes/utils'
import { ClickEvent } from 'devextreme/ui/button'
import { Popup } from 'devextreme-react'
import OutlookAppointmentSelector from '@/routes/tasks/OutlookAppointmentSelector'
import notify from 'devextreme/ui/notify'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import { Roles } from '@/auth/azure/Roles'
import { useScreenSize } from '@/themes/media-query'
import { TaskPlanner } from '@/routes/tasks/TaskEditor.planner'

const TaskEditorForm = (props: TaskEditorFormProps) => {
  const { creating, task, stati, aziende, impiegati, ordini, isRoleAllowed, userInfo, fetcher } = props
  const { clientId } = useParams()

  const [outlookPopupVisible, setOutlookPopupVisible] = useState(false)
  const [outlookAppointmentForTaskData, setOutlookAppointmentForTaskData] = useState<{
    data: {
      utenti: (user | null | undefined)[]
      data: Date
      durata: number
      remoto: boolean
      provvisorio: boolean
    }
    onSubmitEditor: (
      e: any,
      params: {
        users: (user | null | undefined)[] | undefined
        date: Date | undefined
        duration: number | undefined
        task: task | null | undefined
        remote: boolean | undefined
        provisional: boolean | undefined
      },
    ) => Promise<void>
    task: task
    utentiList: (user | null | undefined)[]
  }>()

  const currentScreenSize = useScreenSize()
  const { service, client } = useQsAdminApiManager()
  const formRef = useRef<FormRef>(null)
  const popupNoteClienteRef = useRef<PopupRef>(null)

  const revalidator = useRevalidator()

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId ? (aziende ? aziende[0] : undefined) : task && 'sede' in task ? task?.sede?.azienda : undefined,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()
  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)
  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    ArraySourceComposer('id', task && 'sede' in task ? task?.sede?.azienda?.sedi : []),
  )
  const [ordiniDataSource, setOrdiniDataSource] = useState<DataSource>(ArraySourceComposer('id', ordini))

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await service.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [service],
  )
  const getSiteOrders = useCallback(async (siteId: number | undefined) => {
    const ordersData = await service.ordine().query((builder, ordine) => {
      builder.filter(ordine.commessa.props.sede.props.id.eq(Number(task.sede?.id)))
      builder.expanding('commessa', (commessaBuilder, commessa) => {
        commessaBuilder.select('id', 'ded_Dis', 'titolo')
      })
      builder.select('id', 'ded_Dis', 'note', 'commessa')
      builder.orderBy(ordine.ded_Dis.desc())
    })
    return ordersData.data.value
  }, [])

  const onAddOutlookAppointmentForTaskClick = (e: ClickEvent) => {
    const tecnici: (user | null | undefined)[] = []
    tecnici.push(task.resp_tecnico ?? null)
    task.tecnici?.map((tecnico: user) => {
      tecnici.push(tecnico ?? null)
    })
    const now = new Date()
    now.setHours(8)
    now.setMinutes(30)
    now.setSeconds(0)
    now.setMilliseconds(0)
    setOutlookAppointmentForTaskData({
      task,
      utentiList: tecnici,
      data: {
        data: now,
        durata: 9,
        utenti: tecnici,
        remoto: false,
        provvisorio: false,
      },
      onSubmitEditor: async (
        e: any,
        params: {
          users: (user | null | undefined)[] | undefined
          date: Date | undefined
          duration: number | undefined
          task: task | null | undefined
          remote: boolean | undefined
          provisional: boolean | undefined
        },
      ) => {
        e.preventDefault()
        try {
          const response = await client.post(
            'api/task/addOutlookAppointment',
            {
              ...params,
            },
            { headers: { 'Content-Type': 'application/json' } },
          )
        } catch (error: unknown) {
          notify(`Errore creazione appuntamento per il task ${task.ded_Dis}. Dettagli : ${error}`, 'error', 2000)
        } finally {
          setOutlookPopupVisible(false)
        }
      },
    })

    setOutlookPopupVisible(true)
  }

  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('site effect')
    getSiteOrders(task.sede?.id).then((ordini: ordine[]) => {
      console.log('ordini', ordini)
      setOrdiniDataSource(ArraySourceComposer('id', ordini))
      formRef.current?.instance().updateData('ordine', null)
    })
    setCurrentSiteChanged(false)
  }, [currentSiteChanged, task.sede, getSiteOrders])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'task_form'}
        formData={task}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="taskValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  {
                    name: 'folder',
                    location: 'after',
                    options: {
                      disabled: false,
                      visible: true,
                      stylingMode: 'text',
                      icon: 'folder',
                      hint: 'Apri',
                      onClick: () => {
                        if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                        else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                      },
                    },
                  },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                readOnly: !isRoleAllowed || (clientId ? true : false),
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  if (e.value) {
                    setCurrentCompany(e.value)
                    setCurrentCompanyChanged(true)
                  }
                },
              }}
            >
              <RequiredRule message="Azienda obbligatoria"></RequiredRule>
            </SimpleItem>
            {SiteSimpleItem({
              dataSource: sediDataSource,
              onValueChanged: (e: ValueChangedEventSelectBox) => {
                if (e.previousValue?.id === e.value?.id) return
                console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                if (e.value) setCurrentSiteChanged(true)
              },
              rules: [
                {
                  type: 'required',
                  message: `Sede obbligatoria`,
                },
              ],
              readOnly: !isRoleAllowed,
              onInfoClick: () => {
                if (task?.sede) {
                  setCurrentSite(task.sede)
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
            })}
            <SimpleItem
              dataField="ordine"
              label={{ text: 'Ordine' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  {
                    name: 'folder',
                    location: 'after',
                    options: {
                      visible: true,
                      stylingMode: 'text',
                      icon: 'folder',
                      hint: 'Apri',
                      onClick: () => {
                        if (task.ordine) window.open(`/orders/${task.ordine.id}`, '_blank')
                      },
                    },
                  },
                ],
                dataSource: ordiniDataSource,
                placeholder: 'Seleziona ordine...',
                displayExpr: 'ded_Dis',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare ordine',
                  hideOnOutsideClick: true,
                },
                readOnly: !isUserRoleAllowed(userInfo?.roles, [
                  Roles.GlobalAdministrator,
                  Roles.Administrator,
                  Roles.Supervisor,
                ]),
                onValueChanged: (e: ValueChangedEvent) => {
                  console.log('FRM CHANGED', task)
                  props.onOrderChanged(e.value)
                },
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
                readOnly: creating || !isRoleAllowed,
              }}
            >
              <RequiredRule message="Stato obbligatorio" />
            </SimpleItem>
            <SimpleItem
              dataField="resp_tecnico"
              label={{ text: 'Responsabile' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', impiegati),
                placeholder: 'Seleziona responsabile...',
                displayExpr: 'fullname',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare responsabile',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Responsabile tecnico obbligatorio" />
            </SimpleItem>
            <SimpleItem
              dataField="proprietario"
              label={{ text: 'Autore' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', impiegati),
                placeholder: 'Seleziona proprietario...',
                displayExpr: 'fullname',
                searchEnabled: true,
                dropDownCentered: true,
                readOnly: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare proprietario',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Proprietario obbligatorio" />
            </SimpleItem>
            <SimpleItem
              colSpan={2}
              dataField="tecnici"
              editorType="dxTagBox"
              label={{ text: 'Tecnici Ausiliari' }}
              editorOptions={{
                dataSource: ArraySourceComposer('id', impiegati),
                displayExpr: 'fullname',
                placeholder: 'Seleziona tecnici ausiliari...',
                applyValueMode: 'instantly',
                showSelectionControls: true,
              }}
            ></SimpleItem>
            <SimpleItem
              dataField="apertura"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Apertura' }}
            >
              <RequiredRule message="Data apertura obbligatoria" />
            </SimpleItem>
            <SimpleItem
              dataField="consegna_prevista"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Consegna Prevista' }}
            ></SimpleItem>
            <SimpleItem
              dataField="chiusura"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                readOnly: creating || !isRoleAllowed,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Chiusura' }}
            ></SimpleItem>
            {task.ordine?.offerta?.analisi && (
              <SimpleItem
                label={{ text: 'Analisi' }}
                editorType="dxSelectBox"
                editorOptions={{
                  buttons: [
                    {
                      name: 'folder',
                      location: 'after',
                      options: {
                        disabled: false,
                        visible: true,
                        stylingMode: 'text',
                        icon: 'folder',
                        hint: 'Apri',
                        onClick: () => {
                          if (task.ordine?.offerta?.analisi)
                            window.open(`/analysis/${task.ordine.offerta.analisi.id}`, '_blank')
                        },
                      },
                    },
                  ],
                  dataSource: ArraySourceComposer(
                    'id',
                    task.ordine?.offerta?.analisi ? [task.ordine?.offerta?.analisi] : [],
                  ),
                  value: task.ordine?.offerta?.analisi ?? null,
                  placeholder: 'Seleziona analisi...',
                  displayExpr: 'ded_Dis',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare analisi',
                    hideOnOutsideClick: true,
                  },
                  readOnly: true,
                }}
              ></SimpleItem>
            )}
          </GroupItem>
          <GroupItem colSpan={3}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={true} deferRendering={false} />
              <Tab title="note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxHtmlEditor'}
                  label={{ visible: false }}
                  editorOptions={{
                    stylingMode: 'filled',
                    valueType: 'html',
                    toolbar: toolbarHtmlEditor(currentScreenSize),
                    mediaResizing: {
                      enabled: true,
                    },
                    imageUpload: {
                      tabs: ['file', 'url'],
                      fileUploadMode: 'base64',
                    },
                    height: '370',
                  }}
                >
                  <RequiredRule message="Note obbligatorie"></RequiredRule>
                </SimpleItem>
              </Tab>
              {!creating && (
                <Tab title="note completamento">
                  <SimpleItem
                    dataField={'note_completamento'}
                    editorType={'dxHtmlEditor'}
                    label={{ visible: false }}
                    editorOptions={{
                      stylingMode: 'filled',
                      valueType: 'html',
                      toolbar: toolbarHtmlEditor(currentScreenSize),
                      mediaResizing: {
                        enabled: true,
                      },
                      imageUpload: {
                        tabs: ['file', 'url'],
                        fileUploadMode: 'base64',
                      },
                      height: '370',
                    }}
                  ></SimpleItem>
                </Tab>
              )}
              {!creating && (
                <Tab title="Pianificazione">
                  <SimpleItem>
                    <TaskPlanner task={task} />
                  </SimpleItem>
                </Tab>
              )}
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 2 : 3}>
            <ButtonItem name="btn-appointment">
              <ButtonOptions
                text="Outlook"
                icon="group"
                useSubmitBehavior={false}
                width={'150px'}
                onClick={onAddOutlookAppointmentForTaskClick}
                disabled={
                  creating ||
                  !isUserRoleAllowed(userInfo?.roles, [
                    Roles.GlobalAdministrator,
                    Roles.Supervisor,
                    Roles.TechDeveloper,
                  ])
                }
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('task', task)
                  if (task) {
                    fetcher?.submit(JSON.stringify(task), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <Popup
        width={520}
        height={520}
        hideOnOutsideClick={true}
        showCloseButton={true}
        deferRendering={true}
        title={'Selezionare Data Appuntamento'}
        visible={outlookPopupVisible}
        onHiding={useCallback(() => {
          setOutlookPopupVisible(false)
        }, [setOutlookPopupVisible])}
        className="outlookAppointment-popup-content"
      >
        <OutlookAppointmentSelector
          data={outlookAppointmentForTaskData?.data}
          onSubmitEditor={outlookAppointmentForTaskData?.onSubmitEditor}
          utentiList={outlookAppointmentForTaskData?.utentiList}
          task={outlookAppointmentForTaskData?.task}
        />
      </Popup>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
      ></FormPopupClientNote>
    </>
  )
}

export default TaskEditorForm
