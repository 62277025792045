import { ArraySourceComposer } from '@/auth/api/connector'
import { GridCellColor } from '@/enums'
import { ad_quote, ordine } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { DataGrid } from 'devextreme-react'
import { Column, Paging, Button as GridButton } from 'devextreme-react/cjs/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'

const ProjectEditorFormQuotesGrid = (props: { quotes?: ad_quote[] }) => {
  const { quotes } = props

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'status' && e.value) {
      switch (e.value) {
        case 'ORDERED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'UNDER REVIEW': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
        case 'QUOTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.CORNFLOWERBLUE}`
          break
        }

        case 'DRAFT': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.GREY}`
          break
        }
        case 'FINALIZING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.VIOLET}`
          break
        }
        case 'EXPIRED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.BROWN}`
          break
        }
        case 'CANCELLED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`
          break
        }
        case 'CANCELLING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`
          break
        }
        case 'FAILED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.RED}`
          break
        }
        case 'ORDER SUBMITTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.LILAC}`
          break
        }
      }
    }
  }

  return (
    <>
      <DataGrid
        id={`project-quotes-grid`}
        dataSource={ArraySourceComposer('id', quotes ?? [])}
        noDataText="Nessuna quota associata alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={false}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/quotes/${e.data.id}`, '_blank')
        }}
      >
        <Paging defaultPageSize={8} />
        <Column type="buttons" width={'4%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/quotes/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column dataField={'id'} caption={'ID'} visible={false} />,
        <Column dataField="transactionId" caption="ID TRANSACTION" />
        <Column dataField="number" caption="NUMBER" />
        <Column dataField="csn.csn" caption="CSN" />
        <Column dataField="status" caption="STATO" />
        <Column dataField="contact.fullname" caption="CONTATTO" />
        <Column dataField="admin.fullname" caption="AMMINISTRATORE" />
        <Column dataField="auto" caption="AUTOMATICA" />
      </DataGrid>
    </>
  )
}

export default ProjectEditorFormQuotesGrid
