import { Roles } from '@/auth/azure/Roles'
import TicketsHistoricPage from '@/routes/tickets/historic/TicketsHistoricPage'
import { RoleRouteObject } from '@/types'
import { RouteObject } from 'react-router-dom'

const TicketsHistoricPageRoute = {
  path: 'historic',
  element: <TicketsHistoricPage />,
  //   loader: ticketEditorRouteLoader,
  //   action: ticketEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default TicketsHistoricPageRoute
