import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ad_product_catalog, ad_quote, contatto_aziendale } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { LineItem, Offer, QuoteRequest } from '@/routes/quotes/QuoteEditor.types'
import {
  Connectivity,
  IntendedUsage,
  LineItemModel,
  OfferModel,
  TermModel,
} from '@/routes/quotes/line-items/LineItem.types'
import { GetQuoteDetailsResponse, Pricing } from '@/routes/quotes/pws/GetQuoteDetails.type'

export const CreateItemModel = (action: string, creating: boolean, index: number): LineItemModel => {
  const iu: IntendedUsage = {
    code: 'COM',
    description: 'Commercial',
  }
  const conn: Connectivity = {
    code: 'C100',
    description: 'Online',
  }
  const item: LineItemModel = {
    lineNumber: index,
    operation: creating ? '' : 'Insert',
  }
  switch (action) {
    case 'NEW': {
      const off: OfferModel = {
        term: null,
        intendedUsage: iu,
        connectivity: conn,
      }
      item.productCatalog = null
      item.action = 'New'
      item.quantity = 0
      item.startDate = null
      item.offer = off
      break
    }
    case 'RENEWAL': {
      item.action = 'Renewal'
      item.quantity = 0
      item.subscription = null
      break
    }
    case 'SWITCH PRODUCT': {
      const off: OfferModel = {
        term: null,
        intendedUsage: iu,
        connectivity: conn,
      }
      item.productCatalog = null
      item.action = 'Switch'
      item.quantity = 0
      item.subscription = null
      item.offer = off
      break
    }
    case 'SWITCH TERM': {
      const off: OfferModel = {
        term: null,
        intendedUsage: iu,
        connectivity: conn,
      }
      item.action = 'Switch'
      item.quantity = 0
      item.subscription = null
      item.offer = off
      break
    }
    case 'EXTENSION': {
      item.action = 'Extension'
      item.subscription = null
      item.endDate = null

      break
    }
    case 'TRUE-UP': {
      const off: OfferModel = {
        term: null,
        intendedUsage: iu,
        connectivity: conn,
      }
      item.action = 'True-Up'
      item.quantity = 0
      item.productCatalog = null
      item.startDate = null
      item.endDate = null
      item.offer = off
      break
    }
    case 'CO-TERM': {
      const off: OfferModel = {
        term: null,
        intendedUsage: iu,
        connectivity: conn,
      }
      item.action = 'Co-term'
      item.quantity = 0
      item.productCatalog = null
      item.subscription = null
      item.startDate = null
      item.offer = off
      break
    }
  }
  return item
}

export const CreateQuoteRequest = (
  creating: boolean,
  quoteFormData: ad_quote,
  lineItems: LineItemModel[],
): QuoteRequest => {
  const quoteRequest: QuoteRequest = {
    azienda: quoteFormData?.azienda,
    techActivityRequired: quoteFormData.attivitaTecnicaRichiesta,
    refCsn: quoteFormData.csn?.csn,
    commessa: quoteFormData.commessa,
    mainContact: quoteFormData.contact,
    admin: quoteFormData.admin ?? null,
    otherContacts: quoteFormData.contatti_addizionali,
    lineItems: [],
  }
  if (!creating) {
    quoteRequest.quoteNumber = quoteFormData.number
    quoteRequest.quoteId = quoteFormData.id
  }
  lineItems.map((item, index) => {
    const lineItem: LineItem = {}
    switch (item.operation) {
      //nuovo item in creazione e vecchio item in update
      case '': {
        lineItem.action = item.action
        if (!creating) {
          lineItem.operation = 'Update'
          lineItem.quoteLineNumber = item.quoteLineNumber
        }
        // eslint-disable-next-line sonarjs/no-nested-switch
        switch (item.action) {
          case 'New': {
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            lineItem.promotionCode = item.promotion ? item.promotion.code : null
            if (creating) {
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
                servicePlan: {
                  code: item.productCatalog?.servicePlanCode,
                  description: item.productCatalog?.servicePlanDescription,
                },
                accessModel: {
                  code: item.productCatalog?.accessModelCode,
                  description: item.productCatalog?.accessModelDescription,
                },
                connectivity: item.offer?.connectivity,
                intendedUsage: item.offer?.intendedUsage,
              }
              lineItem.offeringId = item.productCatalog?.offeringId
              lineItem.offeringName = item.productCatalog?.offeringName
              lineItem.offeringCode = item.productCatalog?.offeringCode
              lineItem.offer = off
            }
            break
          }
          case 'Renewal': {
            lineItem.quantity = item.quantity
            lineItem.promotionCode = item.promotion?.code ?? null
            if (creating)
              lineItem.subscriptionId =
                typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription

            break
          }
          case 'Switch': {
            lineItem.quantity = item.quantity
            lineItem.promotionCode = item.promotion?.code ?? null
            if (item.productCatalog) {
              if (creating) {
                const off: Offer = {
                  term: {
                    code: item.offer?.term?.termCode,
                    description: item.offer?.term?.termDescription,
                  },
                  servicePlan: {
                    code: item.productCatalog?.servicePlanCode,
                    description: item.productCatalog?.servicePlanDescription,
                  },
                  accessModel: {
                    code: item.productCatalog?.accessModelCode,
                    description: item.productCatalog?.accessModelDescription,
                  },
                  connectivity: item.offer?.connectivity,
                  intendedUsage: item.offer?.intendedUsage,
                }
                lineItem.offeringId = item.productCatalog?.offeringId
                lineItem.offeringName = item.productCatalog?.offeringName
                lineItem.offeringCode = item.productCatalog?.offeringCode
                lineItem.referenceSubscriptionId =
                  typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
                lineItem.offer = off
              }
            } else {
              if (creating) {
                lineItem.subscriptionId =
                  typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
                const off: Offer = {
                  term: {
                    code: item.offer?.term?.termCode,
                    description: item.offer?.term?.termDescription,
                  },
                }
                lineItem.offer = off
              }
            }

            break
          }
          case 'Extension': {
            lineItem.endDate = item.endDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
              : null
            if (creating)
              lineItem.subscriptionId =
                typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription

            break
          }
          case 'Co-term': {
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            lineItem.endDate = item.endDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
              : null
            lineItem.promotionCode = item.promotion ? item.promotion.code : null
            if (creating) {
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
                servicePlan: {
                  code: item.productCatalog?.servicePlanCode,
                  description: item.productCatalog?.servicePlanDescription,
                },
                accessModel: {
                  code: item.productCatalog?.accessModelCode,
                  description: item.productCatalog?.accessModelDescription,
                },
                connectivity: item.offer?.connectivity,
                intendedUsage: item.offer?.intendedUsage,
              }
              lineItem.offeringId = item.productCatalog?.offeringId
              lineItem.offeringName = item.productCatalog?.offeringName
              lineItem.offeringCode = item.productCatalog?.offeringCode
              lineItem.offer = off
              lineItem.referenceSubscriptionId =
                typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
            }

            break
          }
          case 'True-Up': {
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            lineItem.endDate = item.endDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
              : null
            if (creating) {
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
                servicePlan: {
                  code: item.productCatalog?.servicePlanCode,
                  description: item.productCatalog?.servicePlanDescription,
                },
                accessModel: {
                  code: item.productCatalog?.accessModelCode,
                  description: item.productCatalog?.accessModelDescription,
                },
                connectivity: item.offer?.connectivity,
                intendedUsage: item.offer?.intendedUsage,
              }
              lineItem.offeringId = item.productCatalog?.offeringId
              lineItem.offeringName = item.productCatalog?.offeringName
              lineItem.offeringCode = item.productCatalog?.offeringCode
              lineItem.offer = off
            }
            break
          }
        }
        break
      }
      //nuovo item in update
      case 'Insert': {
        lineItem.action = item.action
        lineItem.operation = item.operation
        // eslint-disable-next-line sonarjs/no-nested-switch
        switch (item.action) {
          case 'New': {
            const off: Offer = {
              term: {
                code: item.offer?.term?.termCode,
                description: item.offer?.term?.termDescription,
              },
              servicePlan: {
                code: item.productCatalog?.servicePlanCode,
                description: item.productCatalog?.servicePlanDescription,
              },
              accessModel: {
                code: item.productCatalog?.accessModelCode,
                description: item.productCatalog?.accessModelDescription,
              },
              connectivity: item.offer?.connectivity,
              intendedUsage: item.offer?.intendedUsage,
            }
            lineItem.offeringId = item.productCatalog?.offeringId
            lineItem.offer = off
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            lineItem.promotionCode = item.promotion ? item.promotion.code : null
            break
          }
          case 'Renewal': {
            lineItem.quantity = item.quantity
            lineItem.promotionCode = item.promotion?.code ?? null
            lineItem.subscriptionId =
              typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
            break
          }
          case 'Switch': {
            lineItem.quantity = item.quantity
            lineItem.promotionCode = item.promotion?.code ?? null
            if (item.productCatalog) {
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
                servicePlan: {
                  code: item.productCatalog?.servicePlanCode,
                  description: item.productCatalog?.servicePlanDescription,
                },
                accessModel: {
                  code: item.productCatalog?.accessModelCode,
                  description: item.productCatalog?.accessModelDescription,
                },
                connectivity: item.offer?.connectivity,
                intendedUsage: item.offer?.intendedUsage,
              }
              lineItem.offer = off
              lineItem.offeringId = item.productCatalog?.offeringId
              lineItem.referenceSubscriptionId =
                typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
            } else {
              lineItem.subscriptionId =
                typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
              const off: Offer = {
                term: {
                  code: item.offer?.term?.termCode,
                  description: item.offer?.term?.termDescription,
                },
              }
              lineItem.offer = off
            }

            break
          }
          case 'Extension': {
            lineItem.subscriptionId =
              typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
            lineItem.endDate = item.endDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
              : null
            break
          }
          case 'Co-term': {
            lineItem.offeringId = item.productCatalog?.offeringId
            lineItem.referenceSubscriptionId =
              typeof item.subscription === 'object' ? item.subscription?.subscriptionId : item.subscription
            lineItem.quantity = item.quantity
            lineItem.startDate = item.startDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.startDate.getFullYear().toString()}-${`0${item.startDate.getMonth() + 1}`.slice(-2)}-${`0${item.startDate.getDate()}`.slice(-2)}`
              : null
            lineItem.endDate = item.endDate
              ? // eslint-disable-next-line sonarjs/no-nested-template-literals
                `${item.endDate.getFullYear().toString()}-${`0${item.endDate.getMonth() + 1}`.slice(-2)}-${`0${item.endDate.getDate()}`.slice(-2)}`
              : null
            lineItem.promotionCode = item.promotion ? item.promotion.code : null
            const off: Offer = {
              term: {
                code: item.offer?.term?.termCode,
                description: item.offer?.term?.termDescription,
              },
              servicePlan: {
                code: item.productCatalog?.servicePlanCode,
                description: item.productCatalog?.servicePlanDescription,
              },
              accessModel: {
                code: item.productCatalog?.accessModelCode,
                description: item.productCatalog?.accessModelDescription,
              },
              connectivity: item.offer?.connectivity,
              intendedUsage: item.offer?.intendedUsage,
            }
            lineItem.offer = off
            break
          }
        }
        break
      }
      //eliminazione item in update
      case 'Delete': {
        lineItem.quoteLineNumber = item.quoteLineNumber
        lineItem.operation = item.operation
        break
      }
    }
    quoteRequest.lineItems.push(lineItem)
  })
  return quoteRequest
}

export const TransformGetQuoteDetails = (details: GetQuoteDetailsResponse) => {
  const service = useQsAdminApiManager.getState().service
  const lineItemsModel: LineItemModel[] = []
  let pricing: Pricing | null = null

  //lineItems
  details[0].lineItems.map(async (lineItem) => {
    const lineItemModel: LineItemModel = {
      lineNumber: lineItem.lineNumber,
      quoteLineNumber: lineItem.quoteLineNumber,
      operation: '',
      action: lineItem.action,
      pricing: lineItem.pricing,
    }
    if ('offer' in lineItem && lineItem.offer) {
      const offerModel: OfferModel = {}
      if ('term' in lineItem.offer && lineItem.offer.term) {
        const termModel: TermModel = {
          termCode: lineItem.offer.term.code,
          termDescription: lineItem.offer.term.description,
        }
        offerModel.term = termModel
      }
      if ('intendedUsage' in lineItem.offer && lineItem.offer.intendedUsage)
        offerModel.intendedUsage = lineItem.offer.intendedUsage
      if ('connectivity' in lineItem.offer && lineItem.offer.connectivity)
        offerModel.connectivity = lineItem.offer.connectivity

      lineItemModel.offer = offerModel
    }

    lineItemModel.quantity = lineItem.quantity ?? undefined
    lineItemModel.startDate = lineItem.startDate ? new Date(lineItem.startDate) : null
    lineItemModel.endDate = lineItem.endDate ? new Date(lineItem.endDate) : null

    if (lineItem.offeringId) {
      const getProduct = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringId.eq(lineItem.offeringId))
        builder.select(
          'offeringId',
          'offeringCode',
          'offeringName',
          'servicePlanCode',
          'servicePlanDescription',
          'accessModelCode',
          'accessModelDescription',
          'termCode',
          'termDescription',
        )
      })
      lineItemModel.productCatalog = getProduct?.data.value[0]
    }

    let productSubscription: ad_product_catalog | null
    if ('subscription' in lineItem && lineItem.subscription) {
      lineItemModel.subscription = lineItem.subscription.id
    }

    if ('referenceSubscription' in lineItem && lineItem.referenceSubscription) {
      lineItemModel.subscription = lineItem.referenceSubscription.id
      //recupero il prodotto della refSubs
      const getRefSubsProduct = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringCode.eq(lineItem.referenceSubscription.offeringCode))
        builder.select(
          'offeringId',
          'offeringCode',
          'offeringName',
          'servicePlanCode',
          'servicePlanDescription',
          'accessModelCode',
          'accessModelDescription',
          'termCode',
          'termDescription',
        )
      })
      productSubscription = getRefSubsProduct.data.value[0]
    }

    if (lineItem.promotionCode) {
      const getPromotion = await service.ad_promotion().query((builder, promotion) => {
        builder.filter(promotion.code.eq(lineItem.promotionCode))
        builder.select('code', 'name', 'description')
      })
      lineItemModel.promotion = getPromotion?.data.value[0]

      const getPromotionsAvailable = await service.ad_promotion().query((builder, promotion) => {
        builder.filter(promotion.offeringCode.eq(lineItemModel.productCatalog?.offeringCode ?? null))
        builder.filter(promotion.orderAction.eq(lineItemModel.action ?? null))
        builder.filter(promotion.startDate.lowerEquals(new Date().toISOString()))
        builder.filter(promotion.endDate.greaterEquals(new Date().toISOString()).or(promotion.endDate.eq(null)))
        builder.filter(promotion.intendedUsageCode.eq('COM').or(promotion.intendedUsageCode.eq(null)))
        builder.filter(
          promotion.termCode.eq(lineItemModel.productCatalog?.termCode ?? null).or(promotion.termCode.eq(null)),
        )
        builder.filter(
          promotion.accessModelCode
            .eq(lineItemModel.productCatalog?.accessModelCode ?? null)
            .or(promotion.accessModelCode.eq(null)),
        )
        builder.filter(promotion.quantityNeeded.lowerEquals(lineItemModel.quantity ?? 0))
        builder.orderBy(promotion.code.asc())
      })
      lineItemModel.promotions = getPromotionsAvailable.data.value
    }

    lineItemsModel.push(lineItemModel)
  })

  //pricing totale della quota
  pricing = details[0].pricing

  return { lineItemsModel, pricing }
}
