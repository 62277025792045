import { RoleRoute, RoleRouteObject, RouteFunctionParams } from '@/types'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import TicketsPage from '@/routes/tickets/TicketsPage'
import TicketCreatorRoute from '@/routes/tickets/TicketCreator.route'
import TicketEditorRoute, {
  assignAction,
  cancelAction,
  incrementCallNumberAction,
  releaseAction,
  removeAssigneeAction,
  sendClosureNoticeAction,
  sendContactNoticeAction,
  sendOtherVendorNoticeAction,
  sendSupportExpiredNoticeAction,
  setUrgentAction,
  takeChargeAction,
} from '@/routes/tickets/TicketEditor.route'
import { stato_ticket } from '@/model/qsadminapi/QsAdminApiModuleModel'
import TicketsHistoricPageRoute from '@/routes/tickets/historic/TicketsHistoricPage.route'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'

export const activeState: stato_ticket = {
  id: 99_999,
  nome: 'ATTIVO',
  note: null,
}

export const ticketsPageRouteLoader = (async ({ request, params }: RouteFunctionParams) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getStati = await qsAdminApi.stato_ticket().query((builder, stato) => {
    builder.select('id', 'nome')
    builder.orderBy(stato.nome.asc())
  })

  const stati: stato_ticket[] = getStati.data.value
  stati.push(activeState)

  const getImpiegati = qsAdminApi.user().query((builder, impiegato) => {
    builder.select('id', 'fullname')
    builder.orderBy(impiegato.fullname.asc())
  })

  return defer({
    stati,
    impiegati: await getImpiegati,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [Roles.GlobalAdministrator, Roles.Administrator, Roles.Supervisor, Roles.TechDeveloper],
  })
}) satisfies LoaderFunction

export const TicketsPageRoute: RoleRoute = {
  path: 'tickets',
  children: [
    {
      index: true,
      element: <TicketsPage />,
      loader: ticketsPageRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    TicketCreatorRoute,
    TicketEditorRoute,
    TicketsHistoricPageRoute,
    {
      path: ':ticketId/cancel',
      action: cancelAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/seturgent',
      action: setUrgentAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/incrementcallnumber',
      action: incrementCallNumberAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/removeassignee',
      action: removeAssigneeAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/takecharge',
      action: takeChargeAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/release',
      action: releaseAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/sendclosurenotice',
      action: sendClosureNoticeAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/sendcontactnotice',
      action: sendContactNoticeAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/assign',
      action: assignAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/sendsupportexpirednotice',
      action: sendSupportExpiredNoticeAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    {
      path: ':ticketId/sendothervendornotice',
      action: sendOtherVendorNoticeAction,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
  ],
  errorElement: <RouterErrorHandler />,
  text: 'Ticket',
  icon: 'info',
  mainMenuRoute: true,
  allowedRoles: [Roles.Guest],
}
