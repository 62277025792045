import { Item, Toolbar } from 'devextreme-react/toolbar'
import { Button } from 'devextreme-react/button'
import { Login, ThemeToggle } from '@microsoft/mgt-react'
import './Header.scss'
import type { HeaderProps } from '@/types'
import { ThemeLightSwitcher, ThemeSizeSwitcher } from '../theme-selector/theme-selector'
import { useScreenSize } from '@/themes/media-query'

export default function Header({ menuToggleEnabled, title, toggleMenu }: HeaderProps) {
  const { isXSmall } = useScreenSize()
  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item visible={menuToggleEnabled} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location={'before'} cssClass={'header-title'} text={title} visible={!!title} />
        <Item location={'after'} locateInMenu={'never'}>
          <ThemeSizeSwitcher />
        </Item>
        <Item location={'after'} locateInMenu={'never'}>
          <ThemeLightSwitcher />
        </Item>
        <Item location={'after'} locateInMenu={'never'}>
          <Login showPresence={true} loginView={isXSmall ? 'avatar' : 'compact'} />
        </Item>
      </Toolbar>
    </header>
  )
}
