import React, { useCallback, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Form, { ButtonItem, ButtonOptions, EmailRule, Item, Label, RequiredRule } from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import './ResetPasswordForm.scss'
import { useCognitoManager } from '../../auth/aws/cognitoManager'

export default function ResetPasswordForm() {
  const navigate = useNavigate()
  const resetPassword = useCognitoManager((state) => state.resetPassword)
  const [loading, setLoading] = useState(false)
  const formData = useRef({ email: '', password: '' })

  const onSubmit = useCallback(async (e: any) => {
    e.preventDefault()
    // const { email } = formData.current
    setLoading(true)

    // const result = await resetPassword()
  }, [])

  return (
    <form className={'reset-password-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={{
            stylingMode: 'filled',
            placeholder: 'Email',
            mode: 'email',
          }}
        >
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={{ class: 'submit-button' }}
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Reset my password'}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'login-link'}>
            Return to <Link to={'/login'}>Sign In</Link>
          </div>
        </Item>
      </Form>
    </form>
  )
}
