import { Column, DataGrid, Paging, Sorting } from 'devextreme-react/data-grid'
import { ArraySourceComposer } from '@/auth/api/connector'
import { ad_quote, ad_quote_history } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { GridCellColor } from '@/enums'
import { CellPreparedEvent } from 'devextreme/ui/data_grid'

const QuoteHistory = (props: { quote: ad_quote }) => {
  const { quote } = props
  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'quoteStatus' && e.value) {
      switch (e.value) {
        case 'ORDERED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`
          break
        }
        case 'UNDER REVIEW': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`
          break
        }
        case 'QUOTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.CORNFLOWERBLUE}`
          break
        }

        case 'DRAFT': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.GREY}`
          break
        }
        case 'FINALIZING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.VIOLET}`
          break
        }
        case 'EXPIRED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.BROWN}`
          break
        }
        case 'CANCELLED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`
          break
        }
        case 'CANCELLING': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`
          break
        }
        case 'FAILED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: white; background-color: ${GridCellColor.RED}`
          break
        }
        case 'ORDER SUBMITTED': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.LILAC}`
          break
        }
      }
    }
  }
  return (
    <>
      <DataGrid
        id={`quote-history-grid-${quote && 'id' in quote ? quote?.id : null}`}
        dataSource={ArraySourceComposer(
          'id',
          quote?.ad_quote_histories?.sort((eventoA: ad_quote_history, eventoB: ad_quote_history) => {
            const dataA: Date = new Date(eventoA.eventDate ?? '')
            const dataB: Date = new Date(eventoB.eventDate ?? '')
            return dataB.getTime() - dataA.getTime()
          }),
        )}
        noDataText="Nessun evento associato alla quota"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        defaultFocusedRowIndex={0}
        columnHidingEnabled={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
      >
        <Paging defaultPageSize={8} />
        <Column
          dataField={'eventDate'}
          caption={'DATA'}
          dataType={'date'}
          format={'dd/MM/yyyy HH:mm:ss'}
          allowSorting={false}
        />
        <Column dataField={'quoteStatus'} caption={'STATO'} allowSorting={false} />
        <Column dataField={'message'} caption={'MESSAGGIO'} allowSorting={false} />
      </DataGrid>
    </>
  )
}

export default QuoteHistory
