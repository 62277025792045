import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { attivita_lavorativa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { HourEditorFormActivitiesGridProps } from '@/routes/hours/HourEditor.types'
import { computeTempo } from '@/routes/hours/utils'
import { AxiosError, AxiosResponse } from 'axios'
import { Button } from 'devextreme-react'
import {
  Column,
  DataGrid,
  DataGridRef,
  DataGridTypes,
  Editing,
  Item,
  Paging,
  Summary,
  Toolbar,
  TotalItem,
  Button as GridButton,
} from 'devextreme-react/data-grid'
import DataSource from 'devextreme/data/data_source'
import ODataStore from 'devextreme/data/odata/store'
import { ClickEvent } from 'devextreme/ui/button'
import type { ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import notify from 'devextreme/ui/notify'
import { Duration } from 'luxon'
import { memo, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const HourEditorFormActivitiesGrid = (props: HourEditorFormActivitiesGridProps) => {
  const { currentFilter, readOnly } = props

  const { client } = useQsAdminApiManager()

  const token = useTokenRefresh()
  const navigate = useNavigate()
  const gridRef = useRef<DataGridRef>(null)

  const computeTempoUfficioValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_ufficio)
  }

  const computeTempoClienteValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_cliente)
  }

  const computeTempoTrasfertaValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_trasferta)
  }

  const calculateTempo = (options: DataGridTypes.CustomSummaryInfo) => {
    if (options.summaryProcess === 'start') {
      options.totalValue = options.name === 'TempoClienteUfficioTotale' ? { ore: 0, minuti: 0 } : 0
    }

    if (options.name === 'TempoTrasfertaTotale' && options.summaryProcess === 'calculate') {
      const row: attivita_lavorativa = options.value
      const duration: Duration = Duration.fromISO(row.tempo_trasferta)
      options.totalValue += duration.toMillis() / 3_600_000
    }

    if (options.name === 'TempoClienteUfficioTotale' && options.summaryProcess === 'calculate') {
      const row: attivita_lavorativa = options.value
      const durationClient: Duration = Duration.fromISO(row.tempo_cliente)
      const durationOffice: Duration = Duration.fromISO(row.tempo_ufficio)
      options.totalValue.minuti += durationClient.minutes
      options.totalValue.minuti += durationOffice.minutes
      options.totalValue.ore += durationClient.hours
      options.totalValue.ore += durationOffice.hours
      //calcolo i minuti tramutabili in ore
      const ore = Math.floor(options.totalValue.minuti / 60)
      const minuti = options.totalValue.minuti % 60
      if (ore > 0) {
        options.totalValue.ore += ore
        options.totalValue.minuti = minuti
      }
    }
  }

  const dailyActivitiesDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_lavorativa`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: currentFilter,
    expand: ['centro_costo', 'causale', 'piattaforma', 'sede($expand=azienda)', 'task'],
  })

  const handleDuplicateActivityClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    if (rowkey) {
      gridRef?.current?.instance().beginCustomLoading('Duplicazione attività in corso....')
      gridRef.current
        ?.instance()
        .byKey(rowkey)
        .then(async (rowdata: attivita_lavorativa) => {
          client
            .post(
              '/api/attivitalavorativa/duplicateactivity',
              { activityId: rowdata.id, activityDate: gridRef.current?.instance().getVisibleRows()[0].data.data },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              },
            )
            .then((response: AxiosResponse) => {
              gridRef.current?.instance().refresh()
            })
            .catch((error: AxiosError) => {
              notify(`Errore duplicazione attività. Dettagli : ${error}`, 'error', 2000)
            })
            .finally(() => {
              gridRef.current?.instance().endCustomLoading()
            })
        })
    } else notify(`Selezionare un' attività da duplicare.`, 'warning', 2000)
  }

  return (
    <>
      <DataGrid
        id={`daily-activity-grid`}
        dataSource={dailyActivitiesDataSource}
        noDataText="Nessuna attività inserita nella data indicata"
        ref={gridRef}
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') navigate(`/hours/${e.data.id}`)
        }}
      >
        <Paging defaultPageSize={8} />
        <Editing mode="batch" allowAdding={false} allowDeleting={!readOnly} allowUpdating={false} />
        <Toolbar>
          <Item location="after">
            <Button
              visible={!readOnly}
              icon="add"
              hint="Nuova attività"
              onClick={(e: ClickEvent) => {
                if (
                  gridRef.current?.instance().getVisibleRows() &&
                  gridRef.current.instance().getVisibleRows().length > 0
                )
                  navigate({
                    pathname: '/hours/new',
                    search: `?date=${gridRef.current.instance().getVisibleRows()[0].data.data}`,
                  })
                else notify(`Deve essere presente almeno un' attività per il giorno indicato.`, 'warning', 3000)
              }}
            />
          </Item>
          <Item
            visible={!readOnly}
            widget="dxButton"
            options={{
              hint: 'Duplica',
              icon: 'newfolder',
              onClick: handleDuplicateActivityClick,
              stylingMode: 'text',
            }}
          ></Item>
          <Item name="saveButton" />
          <Item name="revertButton" />
        </Toolbar>
        <Column type="buttons" width={'4%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              navigate(`/hours/${e.row?.data.id}`)
            }}
          />
        </Column>
        {DateColumn({ dataField: 'data', caption: 'DATA', format: 'dd/MM/yyyy', defaultSortOrder: 'desc' })}
        <Column dataField={'centro_costo.nome'} caption={'CENTRO DI COSTO'} />,
        <Column dataField={'causale.nome'} caption="CAUSALE" />
        <Column dataField={'piattaforma.nome'} caption={'PIATTAFORMA'} />
        <Column dataField={'task.ded_Dis'} caption={'TASK'} />
        <Column dataField={'sede.azienda.nome'} caption="AZIENDA" width={'8%'} />
        <Column dataField={'sede.nome'} caption="SEDE" width={'8%'} />
        <Column
          name={'tempo_ufficio'}
          caption="UFFICIO"
          calculateCellValue={computeTempoUfficioValue}
          dataType="datetime"
          format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
        />
        <Column
          name={'tempo_cliente'}
          caption="CLIENTE"
          calculateCellValue={computeTempoClienteValue}
          dataType="datetime"
          format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
        />
        <Column
          name={'tempo_trasferta'}
          caption="TRASFERTA"
          calculateCellValue={computeTempoTrasfertaValue}
          dataType="datetime"
          format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
        />
        <Column dataField={'spese_trasferta'} caption="SPESE TRASFERTA" />
        <Column dataField={'spese_vitto'} caption="VITTO" />
        <Column dataField={'spese_alloggio'} caption="ALLOGGIO" />
        <Column dataField={'note'} caption="NOTE" width={'15%'} />
        <Column type="buttons" width={'4%'} alignment="left">
          <GridButton name="delete"></GridButton>
        </Column>
        <Summary calculateCustomSummary={calculateTempo}>
          <TotalItem
            name="TempoClienteUfficioTotale"
            summaryType="custom"
            customizeText={(itemInfo: any) => {
              return `HH.MM ${itemInfo.value.ore}:${itemInfo.value.minuti}`
            }}
            showInColumn="tempo_cliente"
          />
          <TotalItem
            name="TempoTrasfertaTotale"
            summaryType="custom"
            displayFormat="HH {0}"
            showInColumn="tempo_trasferta"
          />
          <TotalItem column="spese_trasferta" summaryType="sum" displayFormat="€ {0}" />
          <TotalItem column="spese_vitto" summaryType="sum" displayFormat="€ {0}" />
          <TotalItem column="spese_alloggio" summaryType="sum" displayFormat="€ {0}" />
        </Summary>
      </DataGrid>
    </>
  )
}

const HourEditorFormActivitiesGridMemoed = memo(HourEditorFormActivitiesGrid, (oldProps, newProps) => {
  return oldProps.currentFilter === newProps.currentFilter
})

export default HourEditorFormActivitiesGridMemoed
