import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { offersPageRouteLoader } from '@/routes/offers/OffersPage.route'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Column,
  ColumnChooser,
  DataGrid,
  Editing,
  Export,
  FilterRow,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  DataGridTypes,
  DataGridRef,
  Sorting,
  Search,
  FilterPanel,
  Pager,
} from 'devextreme-react/data-grid'
import { useScreenSize } from '@/themes/media-query'
import dxDataGrid, { CellPreparedEvent, ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { ClickEvent } from 'devextreme/ui/button'
import { contatto_aziendale, entita_aziendale, offerta } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { confirm } from 'devextreme/ui/dialog'
import { EdmLiteral } from 'devextreme/data/odata/utils'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { GridCellColor } from '@/enums'
import { AxiosError, AxiosResponse } from 'axios'
import { StatoOfferta } from '@/routes/offers/OfferEditor.enums'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { gridTotalItemsLabel } from '@/constants'

const OffersPage = () => {
  const { aziende, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof offersPageRouteLoader>()
  const token = useTokenRefresh()

  const { service, client } = useQsAdminApiManager()

  const { clientId } = useParams()
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('data_creazione', 'Data', 'Ultimo mese'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])
  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const [currentFilter, setCurrentFilter] = useState<any[]>(clientId ? [['sede/azienda/id', Number(clientId)]] : [])

  const offersDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/offerta`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'sede($expand=azienda($expand=agente($expand=commerciale_qs),rivenditore))',
      'autore',
      'stato',
      'riferimenti',
      'riferimenti_entita',
      'analisi',
      'commessa',
      'ordini',
    ],
    filter: currentFilter.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '100vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'PERSA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`

          break
        }
        case 'APERTA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'ORDINATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.LILAC}`

          break
        }
        case 'SUPERATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`

          break
        }
      }
    }
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const deleteOfferta = async (offerId: number) => {
    return await service.offerta(offerId).delete()
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    console.log('id', rowkey)
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: offerta) => {
        const result = confirm(`<i>Vuoi davvero eliminare l'offerta <b>${rowdata.id}</b>?</i>`, 'Eliminazione offerta')
        result.then((dialogResult) => {
          if (dialogResult === false) return
          deleteOfferta(rowdata.id)
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Offerta '${rowdata['ded_Dis']}' del cliente ${
                    rowdata['sede']?.azienda?.nome ?? 'Non definito'
                  } eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              notify(
                {
                  message: `Errore eliminazione offerta ${error}`,
                  type: 'error',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
        })
      })
  }

  const handleNewRevisionClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef?.current?.instance().beginCustomLoading('Creazione revisione in corso....')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: offerta) => {
        console.log('offerta', rowdata)
        client
          .post('/api/offerta/createrevision', rowdata.id, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response: AxiosResponse) => {
            console.log('response', response.data)
            navigate(`${response.data.id}`)
          })
          .catch((error: AxiosError) => {
            notify(`Errore creazione revisione. Dettagli : ${error}`, 'error', 2000)
          })
          .finally(() => {
            gridRef?.current?.instance().endCustomLoading()
          })
      })
  }

  const handleExportPDFClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef?.current?.instance().beginCustomLoading('Esportazione documento in corso....')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: offerta) => {
        console.log('offerta', rowdata)
        client
          .get('/api/offerta/exportpdf', {
            params: { offerId: Number(rowdata.id) },
            headers: {
              'Content-Disposition': `attachment;filename=${rowdata.mainDocumentUrl?.replace('.docx', '')}.pdf`,
              'Content-Type': 'application/octet-stream',
            },
            responseType: 'arraybuffer',
          })
          .then((response: AxiosResponse) => {
            console.log('response', response.data)
            saveAs(
              new Blob([response.data], { type: 'application/octet-stream' }),
              `${rowdata.mainDocumentUrl?.replace('.docx', '')}.pdf`,
            )
          })
          .catch((error: AxiosError) => {
            notify(`Errore esportazione documento. Dettagli : ${error}`, 'error', 2000)
          })
          .finally(() => {
            gridRef?.current?.instance().endCustomLoading()
          })
      })
  }

  const handleNewOrderClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: offerta) => {
        console.log('offerta', rowdata)
        if (rowdata.stato?.id === StatoOfferta.APERTA)
          navigate({
            pathname: '/orders/new',
            search: `?offerId=${rowdata?.id}`,
          })
        else notify(`L'offerta deve essere nello stato 'APERTA'. Stato: '${rowdata.stato?.nome}'`, 'warning', 3000)
      })
  }

  const handleOnClickSendOfferToReseller = async (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: offerta) => {
        console.log('offerta', rowdata)
        if (rowdata.sede?.azienda?.rivenditore) {
          gridRef?.current?.instance().beginCustomLoading('Invio email al rivenditore in corso....')
          client
            .post('/api/offerta/sendoffertoreseller', rowdata.id, {
              headers: {
                'Content-Type': 'application/json',
              },
            })
            .then((response: AxiosResponse) => {
              console.log('response', response.data)
              notify(`Offerta inviata correttamente al rivenditore.`, 'success', 2000)
            })
            .catch((error: AxiosError) => {
              notify(`Errore invio offerta al rivenditore. Dettagli : ${error}`, 'error', 2000)
            })
            .finally(() => {
              gridRef?.current?.instance().endCustomLoading()
            })
        } else
          notify(`L'offerta deve essere di un rivenditore. Cliente: '${rowdata.sede?.azienda?.nome}'`, 'warning', 3000)
      })
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Offerte')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Offers.xlsx')
      })
    })
  }

  const calculateRiferimentiValue = (rowData: offerta) => {
    let riferimenti: string = ''
    rowData.riferimenti?.map(
      (contatto: contatto_aziendale) => (riferimenti = `${riferimenti + contatto.fullname?.toUpperCase()} `),
    )
    rowData.riferimenti_entita?.map(
      (contatto: entita_aziendale) => (riferimenti = `${riferimenti + contatto.nome?.toUpperCase()} `),
    )
    return riferimenti.trim()
  }

  const calculateRiferimentiFilterExpression = (
    filterValue: any,
    selectedFilterOperation: string | null,
    target: string,
  ): string | (() => any) | Array<any> => {
    console.log('FLT', filterValue, selectedFilterOperation, target)

    if (target === 'filterBuilder' || target === 'filterRow') {
      switch (selectedFilterOperation) {
        case '=': {
          return [
            [new EdmLiteral(`riferimenti/any(a: a/fullname eq '${filterValue}')`), '=', true],
            'or',
            [new EdmLiteral(`riferimenti_entita/any(a: a/nome eq '${filterValue}')`), '=', true],
          ]
        }
        case '<>': {
          return [
            [new EdmLiteral(`riferimenti/any(a: a/fullname eq '${filterValue}')`), '=', false],
            'and',
            [new EdmLiteral(`riferimenti_entita/any(a: a/nome eq '${filterValue}')`), '=', false],
          ]
        }
        case 'contains':
        case 'startswith':
        case 'endswith': {
          return [
            [new EdmLiteral(`riferimenti/any(a: ${selectedFilterOperation}(a/fullname, '${filterValue}'))`), '=', true],
            'or',
            [
              new EdmLiteral(`riferimenti_entita/any(a: ${selectedFilterOperation}(a/nome, '${filterValue}'))`),
              '=',
              true,
            ],
          ]
        }
        default: {
          // caso notcontains
          return [
            [new EdmLiteral(`riferimenti_entita/any(a: contains(a/nome, '${filterValue}'))`), '=', false],
            'and',
            [new EdmLiteral(`riferimenti/any(a: contains(a/fullname, '${filterValue}'))`), '=', false],
          ]
        }
      }
    } else if (target === 'search') {
      return [
        [new EdmLiteral(`riferimenti/any(a: contains(a/fullname, '${filterValue}'))`), '=', true],
        'or',
        [new EdmLiteral(`riferimenti_entita/any(a: contains(a/nome, '${filterValue}'))`), '=', true],
      ]
    }

    return filterValue
  }

  const calculateOrdineFilterExpression = (
    filterValue: any,
    selectedFilterOperation: string | null,
    target: string,
  ): string | (() => any) | Array<any> => {
    console.log('FLT', filterValue, selectedFilterOperation, target)

    if (target === 'filterBuilder' || target === 'filterRow') {
      switch (selectedFilterOperation) {
        case '=': {
          return [[new EdmLiteral(`ordini/any(a: a/ded_Dis eq '${filterValue}')`), '=', true]]
        }
        case '<>': {
          return [[new EdmLiteral(`ordini/any(a: a/ded_Dis eq '${filterValue}')`), '=', false]]
        }
        case 'contains':
        case 'startswith':
        case 'endswith': {
          return [[new EdmLiteral(`ordini/any(a: ${selectedFilterOperation}(a/ded_Dis, '${filterValue}'))`), '=', true]]
        }
        default: {
          // caso notcontains
          return [[new EdmLiteral(`ordini/any(a: contains(a/ded_Dis, '${filterValue}'))`), '=', false]]
        }
      }
    } else if (target === 'search' || target === 'headerFilter') {
      return [[new EdmLiteral(`ordini/any(a: contains(a/ded_Dis, '${filterValue}'))`), '=', true]]
    }

    return filterValue
  }

  return (
    <>
      {!clientId && <h2 className={'content-block'}>Offerte</h2>}
      <div className={clientId ? '' : 'content-block'}>
        {!clientId && (
          <div className="accordion-generic-filter-form">
            <Accordion
              className={filtersApplied ? 'with-filter' : ''}
              collapsible={true}
              onContentReady={(e: ContentReadyEvent<any, any>) => {
                e.component?.collapseItem(0)
              }}
            >
              <AccordionItem icon="filter" title={'Filtri'}>
                <GenericFilterForm
                  FormItemDescriptors={filterConfiguration}
                  resetFilterClicked={function (e: ClickEvent): void {
                    setFilterConfiguration(getFiltersConfiguration())
                  }}
                  filterValueChanged={function (): void {
                    const currentFlts = getCurrentFilter()
                    console.log('GENFLT - VALUE CHNG', currentFlts)
                    if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                      console.log('GENFLT - SETTING FILT', currentFlts)
                      setCurrentFilter(currentFlts)
                    }
                  }}
                />
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <DataGrid
          id={clientId ? 'client-offers-datagrid' : 'offers-datagrid'}
          height={getGridHeight}
          dataSource={offersDataSource}
          noDataText="Nessuna offerta trovata"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onCellPrepared={onCellPrepared}
          onExporting={onExporting}
          ref={gridRef}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
          remoteOperations={{
            filtering: true,
            grouping: false,
            groupPaging: false,
            paging: true,
            sorting: true,
            summary: true,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <FilterRow visible={true} />
          <Sorting mode="multiple"></Sorting>
          <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <FilterPanel visible={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <StateStoring
            enabled={true}
            type="localStorage"
            storageKey={clientId ? 'client-offers-datagrid' : 'offers-datagrid'}
            savingTimeout={50}
          />
          <Column visible={false} type="buttons" width={'2.5%'} alignment="left" showInColumnChooser={false}></Column>
          <Column
            dataField={'id'}
            caption="ID"
            visible={false}
            dataType="number"
            defaultSortOrder="desc"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'offerta', 'id', true)}></HeaderFilter>
          </Column>
          <Column
            dataField="ded_Dis"
            visible={true}
            caption="CODICE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'offerta', 'ded_Dis', true)}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="revisione"
            visible={true}
            caption="REVISIONE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'offerta', 'revisione')}></HeaderFilter>
          </Column>
          <Column
            dataField="sede.azienda.nome"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: offerta = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [Roles.Guest]) ? (
                <>
                  <Link to={`/clients/${data.sede?.azienda?.id}`}>{data.sede?.azienda?.nome}</Link>
                </>
              ) : (
                <>{data.sede?.azienda?.nome}</>
              )
            }}
            caption="AZIENDA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 15 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="sede.nome"
            caption="SEDE"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
            }
          />
          <Column
            dataField="sede.azienda.agente.commerciale_qs.fullname"
            caption="AGENTE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'agente', 'commerciale_qs.fullname')}>
              <Search enabled={true} searchExpr={'commerciale_qs.fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="autore.fullname"
            caption="AUTORE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'offerta', 'autore.fullname')}>
              <Search enabled={true} searchExpr={'autore.fullname'} />
            </HeaderFilter>
          </Column>
          <Column
            name={'info_riferimenti'}
            caption={'RIFERIMENTI'}
            calculateFilterExpression={calculateRiferimentiFilterExpression}
            calculateCellValue={calculateRiferimentiValue}
            allowFiltering={true}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
            }
          />
          {DateColumn({
            dataField: 'data_creazione',
            caption: 'DATA',
            format: 'dd/MM/yyyy',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined,
          })}
          <Column
            dataField="note"
            caption="NOTE"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined
            }
          />
          <Column
            dataField="stato.nome"
            caption="STATO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'offerta', 'stato.nome')}>
              <Search enabled={true} searchExpr={'stato.nome'} />
            </HeaderFilter>
          </Column>
          <Column
            name="info_ordine"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: offerta = cellData.row.data
              console.log('data', data)
              return isUserRoleAllowed(userInfo?.roles, [...defaultCRUDAllowedRoles, Roles.Marketing]) ? (
                <>
                  <Link to={`/orders/${data.ordini?.[0]?.id}`}>{data.ordini?.[0]?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.ordini?.[0]?.ded_Dis}</>
              )
            }}
            caption="ORDINE"
            calculateFilterExpression={calculateOrdineFilterExpression}
            allowFiltering={true}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'ordine', 'ded_Dis', true)}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="analisi.ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: offerta = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [...defaultCRUDAllowedRoles, Roles.Marketing]) ? (
                <>
                  <Link to={`/analysis/${data.analisi?.id}`}>{data.analisi?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.analisi?.ded_Dis}</>
              )
            }}
            caption="ANALISI"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'offerta', 'analisi.ded_Dis', true)}>
              <Search enabled={true} searchExpr={'analisi.ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="commessa.ded_Dis"
            cellRender={(cellData: DataGridTypes.ColumnCellTemplateData) => {
              const data: offerta = cellData.row.data
              return isUserRoleAllowed(userInfo?.roles, [...defaultCRUDAllowedRoles, Roles.Marketing]) ? (
                <>
                  <Link to={`/projects/${data.commessa?.id}`}>{data.commessa?.ded_Dis}</Link>
                </>
              ) : (
                <>{data.commessa?.ded_Dis}</>
              )
            }}
            caption="COMMESSA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'offerta', 'commessa.ded_Dis', true)}>
              <Search enabled={true} searchExpr={'commessa.ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField="attivitaTecnicaRichiesta"
            caption="ATTIVITA' RICHIESTA"
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
            }
          ></Column>
          <Toolbar>
            {!currentScreenSize.isXSmall && <Item name="groupPanel" />}
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Supervisor,
                Roles.Sales,
              ])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuova offerta',
                text: 'Nuova offerta',
              }}
            />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
              ])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Nuova rev',
                text: 'Nuova rev',
                icon: 'newfolder',
                onClick: handleNewRevisionClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
                Roles.ExternalSales,
              ])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Esporta documento .PDF',
                text: 'Esporta documento .PDF',
                icon: 'pdffile',
                onClick: handleExportPDFClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
              ])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Crea ordine',
                text: 'Crea ordine',
                icon: 'ordersbox',
                onClick: handleNewOrderClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [
                Roles.GlobalAdministrator,
                Roles.Administrator,
                Roles.Sales,
              ])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Invia Offerta Rivenditore',
                text: 'Invia Offerta Rivenditore',
                icon: 'email',
                onClick: handleOnClickSendOfferToReseller,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Elimina offerta',
                text: 'Elimina offerta',
                icon: 'trash',
                onClick: onDeleteClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
      </div>
    </>
  )
}

export default OffersPage
