import SoftwarePage from './SoftwarePage'
import { RoleRoute } from '@/types'
import { Navigate } from 'react-router'
import { SoftwareQsRoute } from '@/routes/software/qs/SoftwareQsGrid.route'
import { SoftwareAdskRoute } from '@/routes/software/adsk/SoftwareAdskGrid.route'
import { SoftwareAltrePiattaformeRoute } from '@/routes/software/altre-piattaforme/SoftwareAltrePiattaformeGrid.route'
import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'

export const softwareSubRoutes: RoleRoute[] = [
  {
    index: true,
    element: <Navigate to={'qs'} replace={true} />,
    visible: false,
    allowedRoles: [Roles.Guest],
  },
  SoftwareQsRoute,
  SoftwareAdskRoute,
  SoftwareAltrePiattaformeRoute,
]

export const SoftwarePageRoute: RoleRoute = {
  path: 'software',
  element: <SoftwarePage />,
  errorElement: <RouterErrorHandler />,
  children: softwareSubRoutes,
  mainMenuRoute: true,
  text: 'Software',
  icon: 'codeblock',
  allowedRoles: [Roles.Guest],
}
