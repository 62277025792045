import { commessa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RequiredRule, SimpleItem, CustomRule } from 'devextreme-react/cjs/form'
import DataSource from 'devextreme/data/data_source'

const ProjectSimpleItem = (props: {
  onAddClick?: () => void
  onFolderClick?: () => void
  readOnly?: boolean
  required?: boolean
  validationMessage?: string
  customValidationCallback?: (options: {
    column: Record<string, any>
    data: Record<string, any>
    formItem: Record<string, any>
    rule: Record<string, any>
    validator: Record<string, any>
    value: string | number
  }) => boolean
  showClearButton?: boolean
  dataSource: DataSource<any, any>
}) => {
  const {
    onAddClick,
    onFolderClick,
    readOnly,
    required,
    validationMessage,
    customValidationCallback,
    showClearButton,
    dataSource,
  } = props
  const buttonsAction = []
  if (onAddClick)
    buttonsAction.push({
      name: 'add',
      location: 'after',
      options: {
        disabled: readOnly,
        visible: true,
        stylingMode: 'text',
        icon: 'add',
        onClick: onAddClick,
      },
    })
  if (onFolderClick)
    buttonsAction.push({
      name: 'folder',
      location: 'after',
      options: {
        disabled: readOnly,
        visible: true,
        stylingMode: 'text',
        icon: 'folder',
        onClick: onFolderClick,
      },
    })
  buttonsAction.push(
    {
      name: 'clear',
      location: 'after',
    },
    { name: 'dropDown', location: 'after' },
  )
  return (
    <SimpleItem
      dataField="commessa"
      label={{ text: 'Commessa' }}
      editorType="dxSelectBox"
      editorOptions={{
        buttons: buttonsAction,
        dataSource,
        placeholder: 'Seleziona commessa...',
        displayExpr: (item: commessa) => {
          return item ? `${item.ded_Dis}${item.titolo ? ' - ' : ''}${item.titolo ?? ''}` : ''
        },
        showDropDownButton: true,
        dropDownButtonTemplate: 'dropDownButton',
        searchEnabled: true,
        showClearButton: showClearButton ?? false,
        dropDownOptions: {
          showTitle: true,
          title: 'Selezionare commessa',
          hideOnOutsideClick: true,
        },
        readOnly: readOnly ?? false,
      }}
    >
      {required && !customValidationCallback && <RequiredRule message={validationMessage ?? 'Commessa obbligatoria'} />}
      {required && customValidationCallback && (
        <CustomRule
          message={validationMessage ?? 'Commessa obbligatoria'}
          validationCallback={customValidationCallback}
          reevaluate={true}
        />
      )}
    </SimpleItem>
  )
}

export default ProjectSimpleItem
