import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import InterventionEditorForm from '@/routes/interventions/InterventionEditor.form'
import { interventionEditorRouteLoader } from '@/routes/interventions/InterventionEditor.route'
import { InterventionEditorProps } from '@/routes/interventions/InterventionEditor.types'
import { isUserRoleAllowed } from '@/routes/utils'
import { Suspense } from 'react'
import { useFetcher, useParams } from 'react-router-dom'
import { Await, useLoaderData } from 'react-router-typesafe'

const InterventionEditor = (props: InterventionEditorProps) => {
  const { creating } = props

  const { clientId } = useParams()

  const {
    intervention,
    getClients,
    getBillingStates,
    getTechnicians,
    getReferences,
    getTasks,
    getUserInfo,
    defaultCRUDAllowedRoles,
  } = useLoaderData<typeof interventionEditorRouteLoader>()
  const title: string = creating ? `Nuovo intervento` : `Intervento ${intervention?.ded_Dis}`

  const fetcher = useFetcher()
  return (
    <>
      <Suspense fallback={<LoadingPanel />}>
        <Await
          resolve={Promise.all([getClients, getBillingStates, getTechnicians, getReferences, getTasks, getUserInfo])}
        >
          {([clients, billingStates, technicians, references, tasks, userInfo]) => (
            <>
              <h2 className={clientId ? '' : 'content-block'}>{title}</h2>
              <div
                id="editor-intervention-container"
                className={clientId ? 'dx-card responsive-paddings' : 'content-block dx-card responsive-paddings'}
              >
                <fetcher.Form>
                  <InterventionEditorForm
                    creating={creating}
                    intervention={intervention}
                    clients={clients.data.value}
                    billingStates={billingStates.data.value}
                    technicians={technicians.data.value}
                    references={references?.data.value}
                    tasks={tasks?.data.value}
                    isRoleAllowed={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
                    fetcher={fetcher}
                  />
                </fetcher.Form>
              </div>
            </>
          )}
        </Await>
      </Suspense>
    </>
  )
}

export default InterventionEditor
