import { memo, useState } from 'react'
import ODataStore from 'devextreme/data/odata/store'
import DataSource from 'devextreme/data/data_source'
import type { qs_licenza as ISwQs } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { Column, DataGrid, Editing } from 'devextreme-react/data-grid'
import { FormMode } from '@/enums'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import useTokenRefresh from '@/auth/azure/azureManager'
import notify from 'devextreme/ui/notify'

type SoftwareQsEditorHistoryProps = {
  license: ISwQs
  mode: FormMode
  readOnly?: boolean
}

export const SoftwareQsEditorHistory = (props: SoftwareQsEditorHistoryProps) => {
  const [currentQsLicenseData] = useState<ISwQs>(props.license)
  const token = useTokenRefresh()

  const qsLicenseHistoryDataSource = new DataSource({
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/qs_storico_licenza`,
      key: 'id',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    filter: [['licenza.id', currentQsLicenseData?.id]],
    expand: ['sede($expand=azienda)', 'versione', 'licenza'],
  })

  return (
    <DataGrid
      id={`sw-qshst-grid-${currentQsLicenseData?.id}`}
      dataSource={qsLicenseHistoryDataSource}
      keyExpr={'id'}
      showBorders={true}
      showColumnLines={true}
      focusedRowEnabled={true}
      columnHidingEnabled={false}
      allowColumnReordering={true}
      allowColumnResizing={true}
      rowAlternationEnabled={true}
      wordWrapEnabled={false}
      width="100%"
    >
      <Editing
        mode="batch"
        allowUpdating={false}
        allowAdding={false}
        allowDeleting={props.mode !== FormMode.View && !props.readOnly}
      />
      <Column dataField={'id'} width={'3%'} visible={false} />,
      <Column dataField={'data'} caption={'DATA'} width={'12%'} dataType={'date'} format={'dd/MM/yyyy'} />
      <Column dataField={'versione.major'} caption={'VERSIONE'} width={'20%'} />
      <Column dataField={'sede.azienda.nome'} caption={'AZIENDA'} />
      <Column dataField={'sede.nome'} caption={'SEDE'} />
    </DataGrid>
  )
}

const SoftwareQsEditorHistoryMemoed = memo(SoftwareQsEditorHistory, (oldProps, newProps) => {
  return oldProps.license.id === newProps.license.id
})

export default SoftwareQsEditorHistoryMemoed
