import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link, matchRoutes, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Form as DXForm, ButtonItem, ButtonOptions, EmailRule, Item, Label, RequiredRule } from 'devextreme-react/form'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import './LoginForm.scss'
import { useMsal } from '@azure/msal-react'
import { AzureUserInfo, getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'
import { allAuthenticatedChildrenRoutes } from '@/routes/authenticated/AuthenticatedLayout.route'
import { Role } from '@/auth/azure/Roles'
import { EventType } from '@azure/msal-browser'
import { MsalLoadingComponent } from '@/routes/authenticated/AuthenticatedLayout'

export function AuthRedirect({ children }: { children: React.ReactNode }) {
  const { inProgress } = useMsal()
  const [userInfo, setUserInfo] = useState<AzureUserInfo | undefined>(useAzureManager.getState().userInfo)
  const isMobile = /mobi|android/i.test(navigator.userAgent)

  useAzureManager.getState().pca?.addEventCallback((event) => {
    if (!event.eventType) return
    if (event.eventType === EventType.HANDLE_REDIRECT_END) {
      // questo arriva e ha lo userInfo!
      setUserInfo(useAzureManager.getState().userInfo)
    }
  })

  if (inProgress !== 'none' && !userInfo) {
    return <MsalLoadingComponent />
  }

  if (userInfo) {
    if (isMobile) {
      const redirectTo = new URLSearchParams(location.search).get('redirectTo')
      if (redirectTo) {
        const allMatchingRoutes = matchRoutes(allAuthenticatedChildrenRoutes, redirectTo)
        const currentRoute = allMatchingRoutes?.at(-1)?.route
        const hasRequiredRole = currentRoute?.allowedRoles?.some((role: Role) => userInfo?.roles?.hasRole(role))
        return <Navigate to={hasRequiredRole ? redirectTo : '/access-denied'} replace />
      }
    }
    return <Navigate to="/dashboard" replace />
  }

  return <>{children}</>
}

export default function LoginForm() {
  const navigate = useNavigate()
  const { accounts, inProgress, instance } = useMsal()
  const [loading, setLoading] = useState(false)
  const formData = useRef({ email: '', password: '' })
  const redirectTo = new URLSearchParams(location.search).get('redirectTo')

  return (
    <form className={'login-form'}>
      <DXForm formData={formData.current} disabled={loading}>
        <Item dataField={'email'} editorType={'dxTextBox'} editorOptions={emailEditorOptions}>
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <Label visible={false} />
        </Item>
        <Item dataField={'password'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item dataField={'rememberMe'} editorType={'dxCheckBox'} editorOptions={rememberMeEditorOptions}>
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions width={'100%'} type={'default'} useSubmitBehavior={true}>
            <span className="dx-button-text">
              {loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : 'Sign In'}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'link'}>
            <Link to={'/reset-password'}>Forgot password?</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions text={'Create an account'} width={'100%'} onClick={() => navigate('/signup')} />
        </ButtonItem>
        <ButtonItem>
          <ButtonOptions
            icon="icons/microsoft-logo.svg"
            text={'Accesso QS Informatica'}
            width={'100%'}
            type={'success'}
            onClick={async () => {
              const isMobile = /mobi|android/i.test(navigator.userAgent)
              if (isMobile) {
                await instance.loginRedirect()
              } else {
                const authRes = await instance.loginPopup()
                if (authRes) {
                  const userInfo = await getAzureUserInformation()
                  if (redirectTo) {
                    const allMatchingRoutes = matchRoutes(allAuthenticatedChildrenRoutes, redirectTo)
                    const currentRoute = allMatchingRoutes?.at(-1)?.route
                    const hasRequiredRole = currentRoute?.allowedRoles?.some((role: Role) =>
                      userInfo?.roles?.hasRole(role),
                    )
                    navigate(hasRequiredRole ? redirectTo : '/access-denied')
                  } else {
                    navigate('/dashboard')
                  }
                }
              }
            }}
          />
        </ButtonItem>
      </DXForm>
    </form>
  )
}

const emailEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Email',
  mode: 'email',
}
const passwordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Password',
  mode: 'password',
}
const rememberMeEditorOptions = {
  text: 'Remember me',
  elementAttr: { class: 'form-text' },
}
