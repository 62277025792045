import {
  ButtonItem,
  ButtonOptions,
  Form as DxForm,
  FormRef,
  GroupItem,
  SimpleItem,
  Tab,
  TabbedItem,
  TabPanelOptions,
} from 'devextreme-react/form'
import type { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useRef } from 'react'
import { useRevalidator } from 'react-router-dom'
import { FormMode } from '@/enums'
import { ArraySourceComposer } from '@/auth/api/connector'
import { SoftwareAdskEditorFormProps } from '@/routes/software/adsk/SoftwareAdskEditor.types'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import notify from 'devextreme/ui/notify'

export const SoftwareAdskEditorForm = (props: SoftwareAdskEditorFormProps) => {
  const { license, mode, aziendaList, isRoleAllowed, fetcher } = props
  const revalidator = useRevalidator()

  const formAdskRef = useRef<FormRef>(null)

  const today = new Date().toISOString()

  const handleFieldDataChanged = (e: FieldDataChangedEvent) => {
    console.log('FORMDATACHANGED EVENT')
    if (
      formAdskRef.current?.instance().option().isDirty !== null &&
      formAdskRef.current?.instance().option().isDirty !== undefined
    ) {
      console.log('is dirty', formAdskRef.current?.instance().option().isDirty)
      formAdskRef.current
        .instance()
        .getButton('btn-save')
        ?.option('disabled', !formAdskRef.current?.instance().option().isDirty)
      formAdskRef.current
        .instance()
        .getButton('btn-cancel')
        ?.option('disabled', !formAdskRef.current?.instance().option().isDirty)
    }
  }

  return (
    <>
      <LoadingPanel
        visible={!((fetcher?.state === 'idle' || mode === FormMode.View) && revalidator.state === 'idle')}
      />
      <DxForm
        id={`adsk_editor_form ${license && 'id' in license ? license?.id : 'new'}`}
        key={`adsk_editor_form ${license && 'id' in license ? license?.id : 'new'}`}
        formData={license}
        ref={formAdskRef}
        onFieldDataChanged={handleFieldDataChanged}
        // onContentReady={onContent}
        validationGroup={`adskLicenseDataValidation ${license && 'id' in license ? license?.id : 'new'}`}
        readOnly={mode === FormMode.View || !isRoleAllowed}
      >
        <GroupItem colCount={2}>
          <GroupItem colCount={2} cssClass="parentHeight">
            <SimpleItem
              dataField="csn.azienda"
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  {
                    name: 'folder',
                    location: 'after',
                    options: {
                      disabled: false,
                      visible: true,
                      stylingMode: 'text',
                      icon: 'folder',
                      hint: 'Apri',
                      onClick: () => {
                        if (license && 'csn' in license && license.csn)
                          window.open(`/clients/${license.csn.azienda?.id}`, '_blank')
                        else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                      },
                    },
                  },
                ],
                dataSource: ArraySourceComposer('id', aziendaList),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                readOnly: true,
              }}
            ></SimpleItem>
            <SimpleItem dataField="csn.csn" label={{ text: 'Csn' }} editorOptions={{ readOnly: true }} />
            <SimpleItem
              dataField="startDate"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                defaultValue: { today },
                pickerType: 'rollers',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Inizio' }}
            ></SimpleItem>
            <SimpleItem
              dataField="endDate"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                defaultValue: { today },
                pickerType: 'rollers',
                readOnly: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Fine' }}
            ></SimpleItem>
            <SimpleItem dataField="serialNumber" label={{ text: 'Seriale' }} editorOptions={{ readOnly: true }} />
            <SimpleItem dataField="productName" editorOptions={{ readOnly: true }} label={{ text: 'Prodotto' }} />
            <SimpleItem dataField="release" editorOptions={{ readOnly: true }} label={{ text: 'Versione' }} />
            <SimpleItem dataField="licenseType" editorOptions={{ readOnly: true }} label={{ text: 'Licenza' }} />
            <SimpleItem dataField="status" editorOptions={{ readOnly: true }} label={{ text: 'Stato' }} />
            <SimpleItem dataField="quantity" editorOptions={{ readOnly: true }} label={{ text: 'Postazioni' }} />
            <SimpleItem dataField="switchType" editorOptions={{ readOnly: true }} label={{ text: 'Switch' }} />
            <SimpleItem dataField="contract" editorOptions={{ readOnly: true }} label={{ text: 'Contratto' }} />
          </GroupItem>
          <GroupItem colCount={1} colSpan={2}>
            <TabbedItem>
              <TabPanelOptions animationEnabled={true} swipeEnabled={true} deferRendering={false} />
              <Tab title="Note">
                <SimpleItem
                  dataField={'note'}
                  editorType={'dxTextArea'}
                  label={{ visible: false }}
                  editorOptions={{
                    height: '370',
                  }}
                ></SimpleItem>
              </Tab>
              <Tab title="Opzioni">
                <GroupItem colCount={2}>
                  <SimpleItem
                    dataField="extensionDate"
                    editorOptions={{
                      displayFormat: 'dd/MM/yyyy',
                      defaultValue: { today },
                      pickerType: 'rollers',
                      showClearButton: true,
                    }}
                    colSpan={1}
                    editorType="dxDateBox"
                    label={{ text: 'Estensione' }}
                  ></SimpleItem>
                  <SimpleItem
                    dataField="autoRenew"
                    editorOptions={{ readOnly: true }}
                    label={{ text: 'Auto Rinnovo' }}
                  />
                </GroupItem>
              </Tab>
            </TabbedItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCountByScreen={{ xs: 1, sm: 2, md: 2, lg: 2 }}>
            {(mode === FormMode.Edit || mode === FormMode.Create) && (
              <ButtonItem name="btn-save">
                <ButtonOptions
                  disabled={!formAdskRef.current?.instance().option().isDirty}
                  icon="save"
                  text="Salva"
                  width="150px"
                  onClick={async () => {
                    const validationResult = formAdskRef.current?.instance().validate()
                    if (!validationResult?.isValid) return
                    fetcher?.submit(JSON.stringify(license), {
                      method: mode === FormMode.Create ? 'POST' : 'PATCH',
                      encType: 'application/json',
                    })
                  }}
                />
              </ButtonItem>
            )}
            {mode === FormMode.Edit && (
              <ButtonItem
                name="btn-cancel"
                buttonOptions={{
                  disabled: !formAdskRef.current?.instance().option().isDirty,
                  icon: 'undo',
                  text: 'Annulla',
                  width: '150px',
                  onClick() {
                    revalidator.revalidate()
                  },
                }}
              ></ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DxForm>
    </>
  )
}
