import { ActivityEditorFormProps } from '@/routes/activities/ActivityEditor.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FieldDataChangedEvent } from 'devextreme/ui/form'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ArraySourceComposer } from '@/auth/api/connector'
import DataSource from 'devextreme/data/data_source'
import {
  azienda,
  sede,
  contatto_aziendale,
  commessa,
  entita_aziendale,
  rapporto,
} from '@/model/qsadminapi/QsAdminApiModuleModel'
import { useNavigate, useParams, useRevalidator } from 'react-router-dom'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { StatoCommessa } from '@/routes/projects/ProjectEditor.enums'
import { ClickEvent } from 'devextreme/ui/button'
import notify from 'devextreme/ui/notify'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { isUserRoleAllowed, toolbarHtmlEditor } from '@/routes/utils'
import ProjectSimpleItem from '@/components/project-simple-item/ProjectSimpleItem'
import FormPopupProject from '@/components/form-popup-project/FormPopupProject'
import SiteSimpleItem from '@/components/site-simple-item/SiteSimpleItem'
import Popup, { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'
import ContactEditorForm from '@/routes/contacts/ContactEditor.form'
import { isIPerson, onEmailsCreate } from '@/routes/contacts/utils'
import { useScreenSize } from '@/themes/media-query'

const ActivityEditorForm = (props: ActivityEditorFormProps) => {
  const {
    creating,
    activity,
    commesse,
    contatti,
    aziende,
    stati,
    tipologie,
    statiCommessa,
    autore,
    isRoleAllowed,
    fetcher,
  } = props
  const { clientId } = useParams()

  const currentScreenSize = useScreenSize()
  const formRef = useRef<FormRef>(null)
  const navigate = useNavigate()
  const revalidator = useRevalidator()

  const { service } = useQsAdminApiManager()

  const [currentCompany, setCurrentCompany] = useState<azienda | null | undefined>(
    clientId
      ? aziende
        ? aziende[0]
        : undefined
      : activity && 'sede' in activity && activity.sede
        ? activity.sede?.azienda
        : undefined,
  )
  const [currentCompanyChanged, setCurrentCompanyChanged] = useState<boolean>(clientId && creating ? true : false)

  const [sediDataSource, setSediDataSource] = useState<DataSource>(
    activity && 'sede' in activity && activity.sede
      ? ArraySourceComposer('id', activity.sede?.azienda?.sedi)
      : ArraySourceComposer('id', []),
  )
  const [currentSite, setCurrentSite] = useState<sede | null | undefined>()
  const [currentSiteChanged, setCurrentSiteChanged] = useState<boolean>(false)

  const [contattiDataSource, setContattiDataSource] = useState<DataSource>(ArraySourceComposer('id', contatti))

  const [commesseDataSource, setCommesseDataSource] = useState<DataSource>(ArraySourceComposer('id', commesse))

  const qsAdminApi = useQsAdminApiManager.getState().service
  const popupNoteClienteRef = useRef<PopupRef>(null)

  const [currentContact, setCurrentContact] = useState<contatto_aziendale>()
  const [currentRapporti, setCurrentRapporti] = useState<rapporto[]>()
  const refNuovoContattoPopup = useRef<PopupRef>(null)
  const getRapporti = async () => {
    const rapportiData = await qsAdminApi.rapporto().query((builder, rapporto) => {
      builder.select('id', 'nome')
      builder.orderBy(rapporto.nome.asc())
    })
    return rapportiData.data.value
  }

  //commessa form popup
  const popupRefProject = useRef<PopupRef>(null)
  const [statiCommessaDataSource, setStatiCommessaDataSource] = useState<DataSource>(
    ArraySourceComposer('id', statiCommessa),
  )
  const formRefProject = useRef<FormRef>(null)
  const project: commessa = {
    id: 0,
    note: null,
    fine: null,
    titolo: null,
    creazione: new Date().toISOString(),
    anno_rif: new Date().getFullYear(),
    inizio: new Date().toISOString(),
    stato: statiCommessa[0],
    sede: activity.sede,
    autore,
    attachmentsFolderUrl: '',
    legacy: false,
    ded_RootFam: '',
    ded_SubFam: '',
    ded_Dis: '',
  }

  const validateSedeContatto = ({ value }: { value: string | number }) => {
    return activity.sede || activity.contatto_aziendale ? true : false
  }

  const validateSedeAzienda = ({ value }: { value: string | number }) => {
    if (currentCompany) return activity.sede ? true : false
    return true
  }

  const validateDemoProject = ({ value }: { value: string | number }) => {
    console.log('validateDemoProject')
    return activity.tipologia?.nome !== 'DEMO' || value ? true : false
  }

  const getCompanySites = useCallback(
    async (companyId: number) => {
      const sitesData = await qsAdminApi.sede().query((builder, sede) => {
        builder.filter(sede.azienda.props.id.eq(companyId))
        builder.orderBy(sede.principale.desc())
        builder.expanding('azienda', (aziendaSedeBuilder, azienda) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
            sediAziendaSedeBuilder.orderBy(sedeAzienda.principale.desc())
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        builder.select('id', 'nome', 'azienda', 'note')
      })
      return sitesData.data.value
    },
    [qsAdminApi],
  )

  const getSiteContacts = useCallback(
    async (siteId: number) => {
      const contactsData = await qsAdminApi.contatto_aziendale().query((builder, contatto) => {
        builder.filter(contatto.sede.props.id.eq(siteId))
        builder.orderBy(contatto.fullname.asc())
        builder.select('id', 'fullname')
      })
      return contactsData.data.value
    },
    [qsAdminApi],
  )

  const getSiteProjects = useCallback(
    async (siteId: number) => {
      const projectsData = await qsAdminApi.commessa().query((builder, commessa) => {
        builder
          .filter(commessa.sede.props.id.eq(siteId))
          .filter(creating ? commessa.stato.props.id.eq(StatoCommessa.APERTA) : null)
        builder.orderBy(commessa.id.desc())
        builder.select('id', 'ded_Dis', 'titolo')
      })
      return projectsData.data.value
    },
    [qsAdminApi, creating],
  )

  const onContactCreate = async (newdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    console.log('SAVING NEWDATA', newdata)
    if (isIPerson(newdata)) {
      await onEmailsCreate(newdata)
      console.log('CONTACT UPDATE BEFORE', newdata)

      await service
        .contatto_aziendale()
        .create(newdata)
        .then(async (result) => {
          notify(`Contatto creato con successo`, 'success', 2000)
          refNuovoContattoPopup.current?.instance().hide()
        })
        .catch((error) => {
          notify(error, 'error', 2000)
        })

      getSiteContacts(Number(activity.sede?.id)).then((contacts: contatto_aziendale[]) => {
        console.log('contacts', contacts)
        setContattiDataSource(ArraySourceComposer('id', contacts))
      })
    }
    return true
  }

  const onContactReset = async (contactdata: contatto_aziendale | entita_aziendale): Promise<boolean> => {
    return true
  }

  //trigger sull'onchanged di azienda
  useEffect(() => {
    if (!currentCompanyChanged) return
    console.log('COMPANY EFFECT', currentCompany)
    if (currentCompany) {
      getCompanySites(currentCompany?.id).then((sites: sede[]) => {
        console.log('SITES', sites)
        setSediDataSource(ArraySourceComposer('id', sites))
        formRef.current?.instance().updateData('sede', sites[0])
        setCurrentSiteChanged(true)
      })
      setCurrentCompanyChanged(false)
    }
  }, [currentCompanyChanged, currentCompany, getCompanySites])

  //trigger sull'onchanged di sede
  useEffect(() => {
    if (!currentSiteChanged) return
    console.log('SITE EFFECT', activity.sede)
    if (activity.sede) {
      getSiteContacts(activity.sede.id).then((contacts: contatto_aziendale[]) => {
        console.log('contacts', contacts)
        setContattiDataSource(ArraySourceComposer('id', contacts))
        formRef.current?.instance().updateData('contatto_aziendale', null)
      })
      getSiteProjects(activity.sede.id).then((commesse: commessa[]) => {
        console.log('commesse', commesse)
        setCommesseDataSource(ArraySourceComposer('id', commesse))
        formRef.current?.instance().updateData('commessa', null)
      })
      setCurrentSiteChanged(false)
    }
  }, [currentSiteChanged, activity.sede, getSiteContacts, getSiteProjects])

  //trigger iniziale
  useEffect(() => {
    if (activity.commessa && commesse?.findIndex((commessa) => commessa.id === activity.commessa?.id) === -1) {
      commesse?.push(activity.commessa)
      setCommesseDataSource(ArraySourceComposer('id', commesse))
    }
  }, [])

  return (
    <>
      <LoadingPanel visible={!(fetcher?.state === 'idle' && revalidator.state === 'idle')} />
      <DXForm
        id={'activity_form'}
        formData={activity}
        labelLocation={'top'}
        colCount={1}
        showValidationSummary={true}
        onFieldDataChanged={(e: FieldDataChangedEvent) => {
          if (
            formRef.current?.instance().option().isDirty !== null &&
            formRef.current?.instance().option().isDirty !== undefined
          ) {
            console.log('is dirty', formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-save')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
            formRef.current
              .instance()
              .getButton('btn-cancel')
              ?.option('disabled', !formRef.current?.instance().option().isDirty)
          }
        }}
        validationGroup="activityValidation"
        ref={formRef}
        readOnly={!isRoleAllowed}
      >
        <GroupItem colCount={5}>
          <GroupItem colCount={2} colSpan={2}>
            <SimpleItem
              label={{ text: 'Azienda' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  {
                    name: 'folder',
                    location: 'after',
                    options: {
                      disabled: false,
                      visible: true,
                      stylingMode: 'text',
                      icon: 'folder',
                      hint: 'Apri',
                      onClick: () => {
                        if (currentCompany) window.open(`/clients/${currentCompany.id}`, '_blank')
                        else notify(`Deve essere selezionata un' azienda per poterla aprire.`, 'warning', 3000)
                      },
                    },
                  },
                ],
                value: currentCompany,
                dataSource: ArraySourceComposer('id', aziende),
                placeholder: "Seleziona l'azienda...",
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownOptions: {
                  showTitle: true,
                  title: "Selezionare l'azienda",
                  hideOnOutsideClick: true,
                },
                readOnly: !isRoleAllowed || (clientId ? true : false),
                onValueChanged: (e: ValueChangedEventSelectBox) => {
                  if (e.previousValue?.id === e.value?.id) return
                  console.log('COMPANY EVENT CHANGED', e.previousValue, e.value)
                  setCurrentCompany(e.value)
                  setCurrentCompanyChanged(true)
                },
              }}
            ></SimpleItem>
            {SiteSimpleItem({
              dataSource: sediDataSource,
              showClearButton: true,
              onValueChanged: (e: ValueChangedEventSelectBox) => {
                if (e.previousValue?.id === e.value?.id) return
                console.log('SITE EVENT CHANGED', e.previousValue, e.value)
                setCurrentSiteChanged(true)
              },
              rules: [
                {
                  type: 'custom',
                  message: `Sede obbligatoria se 'Contatto' non presente`,
                  validationCallback: validateSedeContatto,
                },
                {
                  type: 'custom',
                  message: `Sede obbligatoria se 'Azienda' presente`,
                  validationCallback: validateSedeAzienda,
                },
              ],
              readOnly: !isRoleAllowed,
              onInfoClick: () => {
                if (activity.sede) {
                  setCurrentSite(activity.sede)
                  popupNoteClienteRef.current?.instance().show()
                } else notify('Deve essere selezionata una sede per aprire le note.', 'warning', 3000)
              },
            })}
            <SimpleItem
              dataField="inizio"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                pickerType: 'rollers',
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Inizio' }}
            >
              <RequiredRule message="Data inizio obbligatoria"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="fine"
              editorOptions={{
                displayFormat: 'dd/MM/yyyy',
                pickerType: 'rollers',
                showClearButton: true,
              }}
              editorType="dxDateBox"
              label={{ text: 'Data Fine' }}
            ></SimpleItem>
            <SimpleItem
              dataField="tipologia"
              label={{ text: 'Tipo' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', tipologie),
                placeholder: 'Seleziona la tipologia...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare tipo',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Tipo obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="stato"
              label={{ text: 'Stato' }}
              editorType="dxLookup"
              editorOptions={{
                dataSource: ArraySourceComposer('id', stati),
                placeholder: 'Seleziona lo stato...',
                displayExpr: 'nome',
                searchEnabled: true,
                dropDownCentered: true,
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare lo stato',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <RequiredRule message="Stato obbligatorio"></RequiredRule>
            </SimpleItem>
            <SimpleItem
              dataField="contatto_aziendale"
              label={{ text: 'Contatto' }}
              editorType="dxSelectBox"
              editorOptions={{
                buttons: [
                  {
                    name: 'add',
                    location: 'after',
                    options: {
                      disabled: !isRoleAllowed,
                      visible: true,
                      stylingMode: 'text',
                      icon: 'add',
                      onClick: (e: ClickEvent) => {
                        if (activity.sede) {
                          const contact: contatto_aziendale = {
                            id: 0,
                            fullname: null,
                            ad_recipient: false,
                            nome: null,
                            cognome: null,
                            ruolo: null,
                            note: null,
                            titolo: null,
                            telefono: null,
                            attivo: true,
                            sede: activity.sede,
                            emails: [
                              {
                                id: 0,
                                email: null,
                                note: null,
                                rif_ad: false,
                              },
                            ],
                          }
                          getRapporti().then((result: rapporto[]) => {
                            setCurrentRapporti(result)
                          })
                          setCurrentContact(contact)
                          refNuovoContattoPopup.current?.instance().show()
                        } else
                          notify(`Deve essere selezionata una sede per poter inserire un contatto.`, 'warning', 3000)
                      },
                    },
                  },
                  { name: 'clear', location: 'after' },
                  { name: 'dropDown', location: 'after' },
                ],
                dataSource: contattiDataSource,
                placeholder: 'Seleziona il contatto...',
                displayExpr: 'fullname',
                searchEnabled: true,
                showDropDownButton: true,
                showClearButton: true,
                dropDownButtonTemplate: 'dropDownButton',
                dropDownOptions: {
                  showTitle: true,
                  title: 'Selezionare il contatto',
                  hideOnOutsideClick: true,
                },
              }}
            >
              <CustomRule
                message="Contatto obbligatorio se 'Sede' non presente"
                validationCallback={validateSedeContatto}
              />
            </SimpleItem>

            {ProjectSimpleItem({
              onAddClick: () => {
                if (activity.sede) {
                  popupRefProject.current?.instance().show()
                } else notify(`Deve essere selezionata un' azienda per poter creare una commessa.`, 'warning', 3000)
              },
              onFolderClick: () => {
                if (activity.commessa) window.open(`/projects/${activity?.commessa?.id}`, '_blank')
                else notify(`Deve essere selezionata una commessa per poterla aprire.`, 'warning', 3000)
              },
              dataSource: commesseDataSource,
              required: true,
              validationMessage: "Commessa obbligatoria se l'attività è di tipo 'DEMO'",
              customValidationCallback: validateDemoProject,
              showClearButton: true,
              readOnly: !isRoleAllowed,
            })}
            <GroupItem colCount={2} colSpan={2}></GroupItem>
            <ButtonItem name="goToPrecActivity" verticalAlignment={'bottom'} colSpan={1}>
              <ButtonOptions
                text={'Precedente'}
                icon={'arrowleft'}
                type="default"
                stylingMode="text"
                disabled={!activity?.precedente}
                onClick={() => {
                  navigate({
                    pathname: `/activities/${activity?.precedente?.id}`,
                  })
                }}
              />
            </ButtonItem>
            <ButtonItem name="addFollowUp" verticalAlignment={'bottom'} colSpan={1}>
              <ButtonOptions
                text={'Follow Up'}
                icon={'add'}
                type="default"
                stylingMode="text"
                disabled={creating || !isRoleAllowed}
                onClick={() => {
                  navigate({
                    pathname: '/activities/new',
                    search: `?prec=${activity?.id}`,
                  })
                }}
              />
            </ButtonItem>
          </GroupItem>
          <GroupItem colSpan={3}>
            <SimpleItem
              dataField={'descrizione'}
              editorType={'dxHtmlEditor'}
              label={{ visible: true }}
              editorOptions={{
                stylingMode: 'filled',
                valueType: 'html',
                toolbar: toolbarHtmlEditor(currentScreenSize),
                mediaResizing: {
                  enabled: true,
                },
                imageUpload: {
                  tabs: ['file', 'url'],
                  fileUploadMode: 'base64',
                },
                height: '330',
              }}
            >
              <RequiredRule message="Descrizione obbligatoria"></RequiredRule>
            </SimpleItem>
          </GroupItem>
        </GroupItem>
        <GroupItem visible={isRoleAllowed} cssClass="last-group">
          <GroupItem cssClass="buttons-group" colCount={creating ? 2 : 3}>
            <ButtonItem name="btn-commessa">
              <ButtonOptions
                text="Commessa"
                icon="check"
                useSubmitBehavior={false}
                width={'150px'}
                disabled={creating || !isRoleAllowed}
                onClick={(e: ClickEvent) => {
                  if (activity?.commessa) {
                    notify(
                      `Attività commerciale già collegata a commessa. Commessa: ${activity.commessa.id}`,
                      'warning',
                      3000,
                    )
                  } else {
                    navigate({
                      pathname: '/projects/new',
                      search: `?activityId=${activity?.id}`,
                    })
                  }
                }}
              />
            </ButtonItem>
            <ButtonItem name="btn-save">
              <ButtonOptions
                disabled={!formRef.current?.instance().option().isDirty}
                icon="save"
                text="Salva"
                width="150px"
                onClick={async () => {
                  const validationResult = formRef.current?.instance().validate()
                  if (!validationResult?.isValid) return
                  console.log('validation ok')
                  console.log('formData', activity)
                  if (activity) {
                    fetcher?.submit(JSON.stringify(activity), {
                      method: creating ? 'POST' : 'PUT',
                      encType: 'application/json',
                    })
                  } else console.error('no data tu submit')
                }}
              />
            </ButtonItem>
            {!creating && (
              <ButtonItem name="btn-cancel">
                <ButtonOptions
                  disabled={!formRef.current?.instance().option().isDirty}
                  width="150px"
                  text="Annulla"
                  icon="undo"
                  onClick={() => {
                    revalidator.revalidate()
                  }}
                />
              </ButtonItem>
            )}
          </GroupItem>
        </GroupItem>
      </DXForm>
      <Popup
        ref={refNuovoContattoPopup}
        id="nuovo_contatto_popup"
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={`Nuovo contatto`}
        container=".dx-viewport"
        resizeEnabled={true}
        width={900}
        height={800}
      >
        <ContactEditorForm
          contact={currentContact}
          rapporti={currentRapporti}
          aziende={currentCompany ? [currentCompany] : []}
          sedi={currentContact && currentContact.sede ? [currentContact.sede] : []}
          creating={true}
          onDataReset={onContactReset}
          onDataSave={onContactCreate}
        ></ContactEditorForm>
      </Popup>
      <FormPopupProject
        data={project}
        formRef={formRefProject}
        popupRef={popupRefProject}
        sediDataSource={sediDataSource}
        statiCommessaDataSource={statiCommessaDataSource}
        aziende={project.sede?.azienda ? [project.sede?.azienda] : []}
        onSaveClick={async () => {
          const validationResult = formRefProject.current?.instance().validate()
          if (!validationResult?.isValid) return
          service
            .commessa()
            .create(project)
            .then((res) => {
              getSiteProjects(Number(activity.sede?.id)).then((res: commessa[]) => {
                setCommesseDataSource(ArraySourceComposer('id', res))
                formRef.current?.instance().updateData('commessa', res[0])
                popupRefProject.current?.instance().hide()
              })
            })
        }}
      ></FormPopupProject>
      <FormPopupClientNote
        data={currentSite}
        refPopup={popupNoteClienteRef}
        refreshDataSourceSites={() => {
          if (currentCompany) {
            getCompanySites(currentCompany?.id).then((sites: sede[]) => {
              console.log('SITES', sites)
              setSediDataSource(ArraySourceComposer('id', sites))
              formRef.current?.instance().updateData(
                'sede',
                sites.find((site: sede) => {
                  return site.id === currentSite?.id
                }),
              )
            })
          }
        }}
      ></FormPopupClientNote>
    </>
  )
}

export default ActivityEditorForm
