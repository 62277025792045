import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { Role, RolesDescriptor } from '@/auth/azure/Roles'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'

export const toolbarHtmlEditor = (screenSize?: {
  isXSmall: boolean
  isSmall: boolean
  isMedium: boolean
  isLarge: boolean
}) => {
  return {
    container: null,
    items: [
      'undo',
      'redo',
      'separator',
      {
        name: 'header',
        acceptedValues: [1, 2, 3, false],
      },
      'separator',
      'bold',
      'italic',
      'strike',
      'underline',
      'separator',
      'alignLeft',
      'alignCenter',
      'alignRight',
      'alignJustify',
      'separator',
      'orderedList',
      'bulletList',
      'separator',
      'color',
      'background',
      'separator',
      'link',
      'image',
      'separator',
      'clear',
      'codeBlock',
      'blockquote',
      'separator',
      'insertTable',
      'deleteTable',
      'insertRowAbove',
      'insertRowBelow',
      'deleteRow',
      'insertColumnLeft',
      'insertColumnRight',
      'deleteColumn',
    ],
    multiline: screenSize?.isLarge || screenSize?.isMedium,
  }
}

export const columnSourceFactory = (
  token: string | undefined,
  entity: string,
  dataField: string,
  sortDesc?: boolean,
) => {
  return {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/${entity}`,
      // key: 'id',
      // keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
    }),
    group: { selector: dataField, desc: true },
    sort: sortDesc === undefined ? [`${dataField}`] : undefined,
    select: ['id', `${dataField}`],
    postProcess: (results: any[]) => {
      console.log('results', results)
      const resultsMap: { text: any; value: any }[] = []
      results.map((itemResult: any) => {
        //array di oggetti del tipo richiesto dal headerFilter
        resultsMap.push({ text: itemResult.key, value: itemResult.key })
      })
      return resultsMap
    },
  }
}

export const isUserRoleAllowed = (
  userRoles: RolesDescriptor | undefined,
  allowedRoles: Role[] | undefined,
): boolean => {
  if (allowedRoles) return allowedRoles.some((role: Role) => userRoles?.hasRole(role))
  return false
}
