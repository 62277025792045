import type { RouteObject } from 'react-router-dom'
import { SingleCard } from '../../../layouts'
import { ChangePasswordForm } from '../../../components'

const ResetCodeRoute: RouteObject = {
  path: '/change-password/:recoveryCode',
  element: (
    <SingleCard title="Change Password">
      <ChangePasswordForm />
    </SingleCard>
  ),
}

export default ResetCodeRoute
