import './themes/generated/variables.css'
import './dx-styles.scss'

import { useEffect, useRef } from 'react'

import { NavigationProvider } from '@/providers/navigation'
import { ThemeProvider, useTheme } from '@/providers/theme'
import { MsalProvider, useIsAuthenticated, useMsal } from '@azure/msal-react'
import { Providers as MGT_Providers, ProviderState } from '@microsoft/mgt-element'
import { Outlet } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { IPublicClientApplication } from '@azure/msal-browser'
import { useNavigate } from 'react-router'
import { locale, loadMessages } from 'devextreme/localization'

function Root() {
  const isAuthenticated = useIsAuthenticated()
  const { inProgress } = useMsal()

  useEffect(() => {
    console.log(
      'isAuthenticated, inProgress:',
      [isAuthenticated, inProgress],
      [typeof isAuthenticated, typeof inProgress],
    )
    MGT_Providers.globalProvider.setState(
      inProgress === 'none'
        ? isAuthenticated
          ? ProviderState.SignedIn
          : ProviderState.SignedOut
        : ProviderState.Loading,
    )
  }, [isAuthenticated, inProgress])

  return (
    <NavigationProvider>
      <Outlet />
    </NavigationProvider>
  )
}

export default function App({ pca, queryClient }: { pca: IPublicClientApplication; queryClient: QueryClient }) {
  useEffect(() => {
    // locale(navigator.language)
    locale('it-IT')
  }, [])

  return (
    <MsalProvider instance={pca}>
      <ThemeProvider>
        <QueryClientProvider client={queryClient}>
          <Root />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ThemeProvider>
    </MsalProvider>
  )
}
