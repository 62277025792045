import { ArraySourceComposer } from '@/auth/api/connector'
import { GridCellColor } from '@/enums'
import { task } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { DataGrid } from 'devextreme-react'
import { Column, Paging, Button as GridButton } from 'devextreme-react/cjs/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'

const ProjectEditorFormTasksGrid = (props: { tasks?: task[] }) => {
  const { tasks } = props

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'APERTO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'COMPLETATO': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.EMERALD}`

          break
        }
      }
    }
  }

  return (
    <>
      <DataGrid
        id={`project-orders-grid`}
        dataSource={ArraySourceComposer(
          'id',
          tasks
            ? tasks.sort((taskA: task, taskB: task) => {
                const dataA: Date = new Date(taskA.apertura ?? '')
                const dataB: Date = new Date(taskB.apertura ?? '')
                return dataB.getTime() - dataA.getTime()
              })
            : [],
        )}
        noDataText="Nessun task associato alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={false}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/tasks/${e.data.id}`, '_blank')
        }}
      >
        <Paging defaultPageSize={8} />
        <Column type="buttons" width={'4%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/tasks/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column dataField={'id'} caption={'ID'} visible={false} />
        <Column dataField={'ded_Dis'} caption={'CODICE'} />
        <Column dataField={'proprietario.fullname'} caption={'AUTORE'} />
        <Column dataField={'resp_tecnico.fullname'} caption={'RESPONSABILE'} />
        <Column dataField={'apertura'} dataType="date" caption={'APERTURA'} format={'dd/MM/yyyy'} />
        <Column dataField={'consegna_prevista'} dataType="date" caption={'CONSEGNA'} format={'dd/MM/yyyy'} />
        <Column dataField={'chiusura'} dataType="date" caption={'CHIUSURA'} format={'dd/MM/yyyy'} />
        <Column dataField={'stato.nome'} caption={'STATO'} />
      </DataGrid>
    </>
  )
}

export default ProjectEditorFormTasksGrid
