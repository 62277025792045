import type { RouteObject } from 'react-router-dom'
import { SingleCard } from '../../../layouts'
import { ResetPasswordForm } from '../../../components'

const ResetRoute: RouteObject = {
  path: '/reset-password',
  element: (
    <SingleCard
      title="Reset Password"
      description="Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
    >
      <ResetPasswordForm />
    </SingleCard>
  ),
}

export default ResetRoute
