import { useLoaderData } from 'react-router-typesafe'
import { clientMasterViewRouteLoader, clientTabs } from '@/routes/clients/client/ClientMasterView.route'
import TabbedSelector from '@/components/TabbedSelector'

export default function ClientMasterView() {
  const client = useLoaderData<typeof clientMasterViewRouteLoader>()
  return (
    <>
      <h2 className={'content-block'}>{client?.nome}</h2>
      <div className={'dx-card responsive-paddings content-block'}>
        <TabbedSelector tabs={clientTabs} />
      </div>
    </>
  )
}
