import { Roles } from '@/auth/azure/Roles'
import HourEditor from '@/routes/hours/HourEditor'
import { hourEditorRouteAction, hourEditorRouteLoader } from '@/routes/hours/HourEditor.route'
import { RoleRouteObject } from '@/types'
import { RouteObject } from 'react-router-dom'

const HourCreatorRoute = {
  path: 'new',
  element: <HourEditor creating={true} />,
  loader: hourEditorRouteLoader,
  action: hourEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default HourCreatorRoute
