import { useScreenSize } from '@/themes/media-query'
import { DataGrid, Popup } from 'devextreme-react'
import { Accordion, Item as AccordionItem } from 'devextreme-react/accordion'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLoaderData } from 'react-router-typesafe'
import {
  Column,
  ColumnChooser,
  Editing,
  Export,
  HeaderFilter,
  Item,
  Scrolling,
  SearchPanel,
  Selection,
  StateStoring,
  Toolbar,
  Summary,
  TotalItem,
  DataGridTypes,
  DataGridRef,
  Sorting,
  FilterRow,
  Search,
  FilterPanel,
  Pager,
} from 'devextreme-react/data-grid'
import dxDataGrid, { ExportingEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import ODataStore from 'devextreme/data/odata/store'
import notify from 'devextreme/ui/notify'
import { ODataStoreRequestConfiguration } from '@/auth/api/config'
import { attivita_lavorativa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { confirm } from 'devextreme/ui/dialog'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'
import { ClickEvent } from 'devextreme/ui/button'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ContentReadyEvent } from 'devextreme/ui/accordion'
import {
  createDateFilterItemDescriptor,
  FormFilterItemDescriptorType,
} from '@/components/filter-form/GenericFilterForm.types'
import GenericFilterForm from '@/components/filter-form/GenericFilterForm'
import { hoursPageRouteLoader } from '@/routes/hours/HoursPage.route'
import { AxiosError, AxiosResponse } from 'axios'
import { computeTempo } from '@/routes/hours/utils'
import useTokenRefresh from '@/auth/azure/azureManager'
import DateColumn from '@/components/date-column/DateColumn'
import { columnSourceFactory, isUserRoleAllowed } from '@/routes/utils'
import { Roles } from '@/auth/azure/Roles'
import { PopupRef } from 'devextreme-react/cjs/popup'
import MonthUserCheckSelector from '@/routes/hours/MonthUserCheckSelector'
import { gridTotalItemsLabel } from '@/constants'

const HoursPage = () => {
  const { aziende, impiegati, userInfo, defaultCRUDAllowedRoles } = useLoaderData<typeof hoursPageRouteLoader>()
  const impiegatoDefault = isUserRoleAllowed(userInfo?.roles, [
    Roles.GlobalAdministrator,
    Roles.Administrator,
    Roles.Supervisor,
  ])
    ? impiegati.data.value.find((impiegato) => impiegato.fullname === userInfo?.name)
    : impiegati.data.value[0]
  const token = useTokenRefresh()

  const popupCurrentMonthUserCheckRef = useRef<PopupRef>(null)
  const [currentMonthUserCheckData, setCurrentMonthUserCheckData] = useState<{
    monthList: { index: number; description: string }[]
    formData: {
      month: { index: number; description: string }
    }
    onSubmitEditor: (
      e: any,
      params: {
        month: number | undefined
      },
    ) => Promise<void>
  }>()
  const currentScreenSize = useScreenSize()
  const gridRef = useRef<DataGridRef>(null)
  const navigate = useNavigate()
  const { service, client } = useQsAdminApiManager()

  const now: Date = new Date()

  const getFiltersConfiguration = useCallback((): FormFilterItemDescriptorType[] => {
    const fltConf: FormFilterItemDescriptorType[] = [
      {
        fieldName: 'azienda',
        placeHolder: 'Azienda',
        valueKeyName: 'id',
        valueDisplayExpr: 'nome',
        values: aziende.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['sede/azienda/id', this.currentValue]]
        },
      },
      {
        fieldName: 'impiegato',
        placeHolder: 'Impiegato',
        valueKeyName: 'id',
        valueDisplayExpr: 'fullname',
        defaultValue: impiegatoDefault?.id,
        values: impiegati.data.value,
        composeFilterItem(): any[] | null {
          if (this.currentValue === undefined || this.currentValue === null) return null
          return [['impiegato/id', this.currentValue]]
        },
      },
      createDateFilterItemDescriptor('data', 'Data', 'Ultimo mese'),
    ]
    console.log('GENFLT - GETCONF :', fltConf)
    return fltConf
  }, [aziende, impiegati])

  const initialFilterConfiguration = useMemo(() => getFiltersConfiguration(), [getFiltersConfiguration])

  const [filterConfiguration, setFilterConfiguration] = useState(initialFilterConfiguration)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)
  const [currentPeriodoFilter, setCurrentPeriodoFilter] = useState<any[]>()
  const [currentImpiegatoFilter, setCurrentImpiegatoFilter] = useState<unknown>()
  const [currentAziendaFilter, setCurrentAziendaFilter] = useState<unknown>()
  const [currentFilter, setCurrentFilter] = useState<any[]>([])
  const [totals, setTotals] = useState<{
    extraTime: number
    totalMealVoucher: number
    totalTime: { minutes: number; hours: number }
    transferTime: number
  }>()

  const getGridHeight = useCallback(() => {
    let h = '79vh'
    if (currentScreenSize.isMedium) {
      h = '75vh'
    } else if (currentScreenSize.isSmall) {
      h = '100vh'
    } else if (currentScreenSize.isXSmall) {
      h = '65vh'
    }
    return h
  }, [currentScreenSize])

  const getCurrentFilter = useCallback((): any[] => {
    let applied: boolean = false
    const currentFlts = []
    for (const flt of filterConfiguration) {
      const fltValue = flt.composeFilterItem()
      if (fltValue !== null) {
        currentFlts.push(fltValue)
      }
      if (!applied && flt.currentValue !== flt.defaultValue) applied = true
      if (flt.fieldName === 'data')
        if (fltValue) setCurrentPeriodoFilter([fltValue])
        else setCurrentPeriodoFilter([])
      if (flt.fieldName === 'impiegato') setCurrentImpiegatoFilter(flt.currentValue)
      if (flt.fieldName === 'azienda') setCurrentAziendaFilter(flt.currentValue)
    }
    setFiltersApplied(applied)
    console.log('GENFLT - CURRENT:', currentFlts)
    return currentFlts
  }, [filterConfiguration])

  const handleCurrentMonthSummaryClick = (e: ClickEvent) => {
    gridRef?.current?.instance().beginCustomLoading('Esportazione totale mese corrente in corso....')
    client
      .get('/api/attivitalavorativa/currentmonthsummary', {
        headers: {
          'Content-Disposition': `attachment;filename=HS-${now.getFullYear()}-${now.getMonth() + 1}.zip`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        saveAs(
          new Blob([response.data], { type: 'application/octet-stream' }),
          `HS-${now.getFullYear()}-${now.getMonth() + 1}.zip`,
        )
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione totale mese corrente. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        gridRef?.current?.instance().endCustomLoading()
      })
  }

  const handleCurrentMonthtRiprefundClick = (e: ClickEvent) => {
    gridRef?.current?.instance().beginCustomLoading('Esportazione rimborsi in corso....')
    client
      .get('/api/attivitalavorativa/currentmonthtriprefund', {
        headers: {
          'Content-Disposition': `attachment;filename=HTF-${now.getFullYear()}-${now.getMonth() + 1}.xlsx`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      })
      .then((response: AxiosResponse) => {
        saveAs(
          new Blob([response.data], { type: 'application/octet-stream' }),
          `HTF-${now.getFullYear()}-${now.getMonth() + 1}.xlsx`,
        )
      })
      .catch((error: AxiosError) => {
        notify(`Errore esportazione rimborsi. Dettagli : ${error}`, 'error', 2000)
      })
      .finally(() => {
        gridRef?.current?.instance().endCustomLoading()
      })
  }

  const handleCurrentMonthUserCheckClick = (e: ClickEvent) => {
    const formatter = new Intl.DateTimeFormat('it', {
      month: 'long',
    })
    setCurrentMonthUserCheckData({
      monthList: [
        {
          index: now.getMonth() - 1 + 1,
          description: formatter.format(new Date(now.getFullYear(), now.getMonth() - 1)),
        },
        { index: now.getMonth() + 1, description: formatter.format(new Date(now.getFullYear(), now.getMonth())) },
      ],
      formData: {
        month: {
          index: now.getMonth() + 1,
          description: formatter.format(new Date(now.getFullYear(), now.getMonth())),
        },
      },
      onSubmitEditor: async (
        e: any,
        params: {
          month: number | undefined
        },
      ) => {
        e.preventDefault()
        try {
          const response = await client.get(`/api/attivitalavorativa/monthusercheck?month=${params.month}`, {
            headers: {
              'Content-Disposition': `attachment;filename=HS-${now.getFullYear()}-${params.month}-${impiegatoDefault?.cognome?.toUpperCase()}.zip`,
              'Content-Type': 'application/octet-stream',
            },
            responseType: 'arraybuffer',
          })
          console.log('DATARE2', now, now.getFullYear(), now.getMonth())
          saveAs(
            new Blob([response.data], { type: 'application/octet-stream' }),
            `HS-${now.getFullYear()}-${params.month}-${impiegatoDefault?.fullname?.toUpperCase()}.zip`,
          )
        } catch (error: unknown) {
          notify(`Errore esportazione check utente mese. Dettagli : ${error}`, 'error', 2000)
        } finally {
          popupCurrentMonthUserCheckRef.current?.instance().hide()
        }
      },
    })

    popupCurrentMonthUserCheckRef.current?.instance().show()
  }

  const handleDuplicateActivityClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef?.current?.instance().beginCustomLoading('Duplicazione attività in corso....')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then(async (rowdata: attivita_lavorativa) => {
        console.log('attivita', rowdata)
        client
          .post(
            '/api/attivitalavorativa/duplicateactivity',
            { activityId: rowdata.id, activityDate: new Date() },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            },
          )
          .then((response: AxiosResponse) => {
            gridRef.current?.instance().refresh()
          })
          .catch((error: AxiosError) => {
            notify(`Errore duplicazione attività. Dettagli : ${error}`, 'error', 2000)
          })
          .finally(() => {
            gridRef.current?.instance().endCustomLoading()
          })
      })
  }

  const onDeleteClick = (e: ClickEvent) => {
    const rowkey = gridRef.current?.instance().option('focusedRowKey')
    gridRef.current
      ?.instance()
      .byKey(rowkey)
      .then((rowdata: attivita_lavorativa) => {
        const result = confirm(
          `<i>Vuoi davvero eliminare l'attività lavorativa <b>${rowdata.ded_Dis}</b>?</i>`,
          'Eliminazione attività lavorativa',
        )
        result.then((dialogResult) => {
          if (dialogResult === false) return
          service
            .attivita_lavorativa(rowdata.id)
            .delete()
            .then(() => {
              // Aggiornamento griglia
              gridRef.current?.instance().refresh()

              notify(
                {
                  message: `Attività lavorativa '${rowdata['id']}' eliminata con successo`,
                  type: 'success',
                  displayTime: 5000,
                },
                {
                  position: 'bottom center',
                  direction: 'up-push',
                },
              )
            })
            .catch((error: unknown) => {
              console.log('error', error)
            })
        })
      })
  }

  const onExporting = (e: ExportingEvent) => {
    ExportDataGridToExcel(e.component)
  }

  const ExportDataGridToExcel = (component: dxDataGrid) => {
    const workbook = new Workbook()
    const worksheet = workbook.addWorksheet('Hours')
    exportDataGrid({ component, worksheet }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Hours.xlsx')
      })
    })
  }

  const computeTempoUfficioValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_ufficio)
  }

  const computeTempoClienteValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_cliente)
  }

  const computeTempoTrasfertaValue = (rowData: attivita_lavorativa) => {
    return computeTempo(rowData.tempo_trasferta)
  }

  const hoursDataSource = {
    store: new ODataStore({
      url: `${import.meta.env.VITE_QSADMINAPI_HOST}/attivita_lavorativa`,
      key: 'id',
      keyType: 'Int32',
      version: 4,
      errorHandler: (e) => {
        console.error(e.errorDetails)
        if (!e.errorDetails) return
        notify(
          {
            message: `Errore : ${e.errorDetails?.message}`,
            type: 'error',
            displayTime: 5000,
          },
          {
            position: 'bottom center',
            direction: 'up-push',
          },
        )
      },
      beforeSend: ODataStoreRequestConfiguration(token),
      deserializeDates: false,
    }),
    expand: [
      'impiegato($expand=reparto)',
      'centro_costo',
      'piattaforma',
      'causale',
      'task',
      'intervento',
      'sede($expand=azienda($expand=agente($expand=commerciale_qs)))',
    ],
    filter: currentFilter?.length > 0 ? currentFilter : null,
    requireTotalCount: true,
  }

  const calculateTotals = (options: DataGridTypes.CustomSummaryInfo) => {
    if (options.summaryProcess !== 'finalize') return
    options.totalValue = `Totale [HH.MM] ${totals?.totalTime} - Straordinari ${totals?.extraTime} - Trasferta ${totals?.transferTime} - Buoni pasto [N] ${totals?.totalMealVoucher}`
  }

  useEffect(() => {
    console.log('currentPeriodo', currentPeriodoFilter)
    if (
      currentPeriodoFilter &&
      currentPeriodoFilter.length > 0 &&
      currentImpiegatoFilter !== undefined &&
      currentImpiegatoFilter !== null &&
      !currentAziendaFilter
    )
      client
        .get(
          `/api/attivitalavorativa/computedaterangetotals?firstRangeDate=${currentPeriodoFilter[0][1][2].toISOString()}&lastRangeDate=${currentPeriodoFilter[0][0][2].toISOString()}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        )
        .then(
          (
            response: AxiosResponse<
              {
                extraTime: number
                totalMealVoucher: number
                totalTime: { minutes: number; hours: number }
                transferTime: number
              },
              {
                extraTime: number
                totalMealVoucher: number
                totalTime: { minutes: number; hours: number }
                transferTime: number
              }
            >,
          ) => {
            setTotals(response.data)
          },
        )
        .catch((error: AxiosError) => {
          notify(`Errore calcolo totali ore. Dettagli : ${error}`, 'error', 2000)
          setTotals({ extraTime: 0, totalMealVoucher: 0, totalTime: { minutes: 0, hours: 0 }, transferTime: 0 })
        })
    else setTotals({ extraTime: 0, totalMealVoucher: 0, totalTime: { minutes: 0, hours: 0 }, transferTime: 0 })
  }, [currentFilter])

  return (
    <>
      <h2 className={'content-block'}>Ore</h2>
      <div className={'content-block'}>
        <div className="accordion-generic-filter-form">
          <Accordion
            className={filtersApplied ? 'with-filter' : ''}
            collapsible={true}
            onContentReady={(e: ContentReadyEvent<any, any>) => {
              e.component?.collapseItem(0)
            }}
          >
            <AccordionItem icon="filter" title={'Filtri'}>
              <GenericFilterForm
                FormItemDescriptors={filterConfiguration}
                resetFilterClicked={function (e: ClickEvent): void {
                  setFilterConfiguration(getFiltersConfiguration())
                }}
                filterValueChanged={function (): void {
                  const currentFlts = getCurrentFilter()
                  console.log(currentFlts)
                  console.log('GENFLT - VALUE CHNG', currentFlts)
                  if (JSON.stringify(currentFlts) !== JSON.stringify(currentFilter)) {
                    console.log('GENFLT - SETTING FILT', currentFlts)
                    setCurrentFilter(currentFlts)
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
        <DataGrid
          id={'hours-datagrid'}
          height={getGridHeight}
          dataSource={hoursDataSource}
          noDataText="Nessuna attività lavorativa trovata"
          className={'dx-card wide-card'}
          showBorders={false}
          showColumnLines={true}
          wordWrapEnabled={false}
          showRowLines={true}
          focusedRowEnabled={true}
          rowAlternationEnabled={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          width="100%"
          onExporting={onExporting}
          ref={gridRef}
          columnHidingEnabled={currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium}
          remoteOperations={{
            filtering: true,
            grouping: true,
            groupPaging: true,
            paging: true,
            sorting: true,
            summary: false,
          }}
          grouping={{
            // Fondamentale per il raggruppamento veloce!!!
            autoExpandAll: true,
          }}
          groupPanel={{
            visible: true,
            emptyPanelText: 'Trascina qui una colonna per raggruppare',
          }}
          onRowDblClick={(e: RowDblClickEvent<any, any>) => {
            if (e.rowType === 'data') navigate(`${e.data.id}`)
          }}
        >
          <Pager
            visible={true}
            allowedPageSizes={'auto'}
            displayMode={'compact'}
            showPageSizeSelector={false}
            showInfo={true}
            showNavigationButtons={false}
            infoText={gridTotalItemsLabel}
          />
          <FilterRow visible={true} />
          <SearchPanel visible={true} width={currentScreenSize.isXSmall ? 200 : 250} />
          <Sorting mode="multiple"></Sorting>
          <FilterPanel visible={true} />
          <Export enabled={true} />
          <Editing allowAdding={true} />
          <HeaderFilter visible={true} />
          <Selection mode="single" />
          <ColumnChooser enabled={true} />
          <Scrolling mode="virtual" />
          <StateStoring enabled={true} type="localStorage" storageKey={'hours-datagrid'} savingTimeout={50} />
          <Column visible={false} type="buttons" width={'2.5%'} alignment="left" showInColumnChooser={false}></Column>
          <Column
            dataField={'id'}
            width={'5%'}
            caption="ID"
            visible={false}
            dataType="number"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 1 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'attivita_lavorativa', 'id', true)}></HeaderFilter>
          </Column>
          {DateColumn({
            dataField: 'data',
            caption: 'DATA',
            width: 'auto',
            format: 'dd/MM/yyyy',
            defaultSortOrder: 'desc',
            hidingPriority:
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 15 : undefined,
          })}
          <Column
            dataField={'centro_costo.nome'}
            caption="CENTRO DI COSTO"
            width={'8%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 14 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'centro_costo', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'piattaforma.nome'}
            caption="PIATTAFORMA"
            width={'8%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 13 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'piattaforma_attivita_lavorativa', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'task.ded_Dis'}
            caption={'TASK'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 11 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'task', 'ded_Dis', true)}>
              <Search enabled={true} searchExpr={'ded_Dis'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'sede.azienda.nome'}
            caption="AZIENDA"
            width={'8%'}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 12 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'azienda', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          <Column
            dataField={'sede.nome'}
            caption="SEDE"
            width={'8%'}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 2 : undefined
            }
          />
          <Column
            name={'tempo_ufficio'}
            caption="UFFICIO"
            calculateCellValue={computeTempoUfficioValue}
            dataType="datetime"
            format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 10 : undefined
            }
          />
          <Column
            name={'tempo_cliente'}
            caption="CLIENTE"
            calculateCellValue={computeTempoClienteValue}
            dataType="datetime"
            format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 9 : undefined
            }
          />
          <Column
            name={'tempo_trasferta'}
            caption="TRASFERTA"
            calculateCellValue={computeTempoTrasfertaValue}
            dataType="datetime"
            format={{ hour: '2-digit', minute: '2-digit', hour12: false }}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 8 : undefined
            }
          />
          <Column
            dataField={'spese_trasferta'}
            caption="SPESE TRASFERTA"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 7 : undefined
            }
          >
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'attivita_lavorativa', 'spese_trasferta', true)}
            ></HeaderFilter>
          </Column>
          <Column
            dataField={'spese_vitto'}
            caption="VITTO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 6 : undefined
            }
          >
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'attivita_lavorativa', 'spese_vitto', true)}
            ></HeaderFilter>
          </Column>
          <Column
            dataField={'spese_alloggio'}
            caption="ALLOGGIO"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 5 : undefined
            }
          >
            <HeaderFilter
              dataSource={columnSourceFactory(token, 'attivita_lavorativa', 'spese_alloggio', true)}
            ></HeaderFilter>
          </Column>
          <Column
            dataField={'note'}
            caption="NOTE"
            width={'20%'}
            allowHeaderFiltering={false}
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 4 : undefined
            }
          />
          <Column
            dataField={'causale.nome'}
            caption="CAUSALE"
            hidingPriority={
              currentScreenSize.isSmall || currentScreenSize.isXSmall || currentScreenSize.isMedium ? 3 : undefined
            }
          >
            <HeaderFilter dataSource={columnSourceFactory(token, 'causale_centro_costo', 'nome')}>
              <Search enabled={true} searchExpr={'nome'} />
            </HeaderFilter>
          </Column>
          {currentImpiegatoFilter !== undefined && currentImpiegatoFilter !== null && (
            <Summary calculateCustomSummary={calculateTotals}>
              <TotalItem column="spese_trasferta" summaryType="sum" displayFormat="€ {0}" />
              <TotalItem column="spese_vitto" summaryType="sum" displayFormat="€ {0}" />
              <TotalItem column="spese_alloggio" summaryType="sum" displayFormat="€ {0}" />
            </Summary>
          )}
          <Toolbar>
            {!currentScreenSize.isXSmall && <Item name="groupPanel" />}
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              name="addRowButton"
              options={{
                onClick: () => {
                  navigate(`new`)
                },
                hint: 'Nuova attività',
                text: 'Nuova attività',
              }}
            />
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Esporta rimborsi',
                text: 'Esporta rimborsi',
                icon: 'money',
                onClick: handleCurrentMonthtRiprefundClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, [Roles.GlobalAdministrator, Roles.Administrator])}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Esporta totale mese corrente',
                text: 'Esporta totale mese corrente',
                icon: 'formula',
                onClick: handleCurrentMonthSummaryClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Esporta check utente mese',
                text: 'Esporta check utente mese',
                icon: 'check',
                onClick: handleCurrentMonthUserCheckClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Duplica',
                text: 'Duplica',
                icon: 'newfolder',
                onClick: handleDuplicateActivityClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              visible={isUserRoleAllowed(userInfo?.roles, defaultCRUDAllowedRoles)}
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              widget="dxButton"
              options={{
                hint: 'Elimina attività',
                text: 'Elimina attività',
                icon: 'trash',
                onClick: onDeleteClick,
                stylingMode: 'text',
              }}
            ></Item>
            <Item
              name="columnChooserButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Scelta colonne', text: 'Scelta colonne' }}
            />
            <Item
              name="exportButton"
              locateInMenu={currentScreenSize.isSmall || currentScreenSize.isXSmall ? 'always' : 'auto'}
              showText="inMenu"
              options={{ hint: 'Esporta dati in excel', text: 'Esporta dati in excel' }}
            />
            <Item name="searchPanel" />
          </Toolbar>
        </DataGrid>
        <h6
          style={{ fontSize: '0.9em', float: 'left', marginTop: '10px' }}
        >{`Totale [HH.MM] ${totals?.totalTime.hours}.${totals?.totalTime.minutes} - Straordinari ${totals?.extraTime} - Trasferta ${totals?.transferTime} - Buoni pasto ${totals?.totalMealVoucher}`}</h6>
        <Popup
          width={300}
          height={300}
          hideOnOutsideClick={true}
          showCloseButton={true}
          deferRendering={true}
          title={'Seleziona mese di riferimento'}
          ref={popupCurrentMonthUserCheckRef}
          className="monthusercheck-popup-content"
        >
          <MonthUserCheckSelector data={currentMonthUserCheckData} />
        </Popup>
      </div>
    </>
  )
}

export default HoursPage
