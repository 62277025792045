import { Roles } from '@/auth/azure/Roles'
import ActivityEditor from '@/routes/activities/ActivityEditor'
import { activityEditorRouteAction, activityEditorRouteLoader } from '@/routes/activities/ActivityEditor.route'
import { RoleRouteObject } from '@/types'

const ActivityCreatorRoute = {
  path: 'new',
  element: <ActivityEditor creating={true} />,
  loader: activityEditorRouteLoader,
  action: activityEditorRouteAction,
  allowedRoles: [Roles.Sales, Roles.Administrator, Roles.GlobalAdministrator],
} as RoleRouteObject

export default ActivityCreatorRoute
