import { DocumentCreatorProps } from '@/components/document/DocumentCreator.types'
import { useEffect, useState } from 'react'
import { ClickEvent } from 'devextreme/ui/button'
import { Get as MgtGet, MgtTemplateProps, File as MgtFile } from '@microsoft/mgt-react'
import { Button, LoadIndicator } from 'devextreme-react'
import { DocumentPermissions } from '@/enums'

const DocumentCreator = (props: DocumentCreatorProps) => {
  const { title, documentUrl, documentGetRef, action, preliminarPermissionsAction } = props
  const [permissions, setPermissions] = useState<DocumentPermissions>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const computePermissions = async () => {
      let objperm: DocumentPermissions = DocumentPermissions.Deny
      if (preliminarPermissionsAction) {
        objperm = await preliminarPermissionsAction()
      }
      setPermissions(objperm)
      setIsLoading(false)
    }

    computePermissions()
  }, [preliminarPermissionsAction])

  if (isLoading) {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  const MgtFileCustom = (props: MgtTemplateProps) => {
    const data = props.dataContext
    return (
      <MgtFile
        onClick={async (event) => {
          window.open(data.webUrl, '_blank')
        }}
        driveId={import.meta.env.VITE_QSADMINSITE_DRIVE_ID}
        itemId={data.id}
      ></MgtFile>
    )
  }

  const MgtFileLoading = (props: MgtTemplateProps) => {
    return <LoadIndicator id="small-indicator" height={20} width={20} />
  }

  const MgtFileError = (props: MgtTemplateProps) => {
    return <div>Impossibile individuare il documento</div>
  }

  const MgtFileNoData = (props: MgtTemplateProps) => {
    const [createDocLoadIndicatorVisible, setCreateDocLoadIndicatorVisible] = useState(false)
    // eslint-disable-next-line unicorn/prefer-ternary
    if (permissions && permissions !== DocumentPermissions.Deny) {
      return (
        <Button
          disabled={permissions === DocumentPermissions.Read}
          stylingMode="outlined"
          type="default"
          width={180}
          height={40}
          onClick={async (e: ClickEvent) => {
            setCreateDocLoadIndicatorVisible(true)

            try {
              await action()
            } finally {
              setCreateDocLoadIndicatorVisible(false)
              documentGetRef.current?.refresh(true)
            }
          }}
        >
          <LoadIndicator className="button-indicator" visible={createDocLoadIndicatorVisible} />
          <span className="dx-button-text">{createDocLoadIndicatorVisible ? 'In creazione...' : `Crea ${title}`}</span>
        </Button>
      )
    } else {
      return <div>Non si dispone dei permessi di accesso necessari. Contattare un amministratore per informazioni</div>
    }
  }

  return (
    <>
      <MgtGet
        ref={documentGetRef}
        resource={`drives/${import.meta.env.VITE_QSADMINSITE_DRIVE_ID}/root:/${documentUrl}`}
      >
        <MgtFileCustom template="default" />
        <MgtFileLoading template="loading" />
        <MgtFileNoData template="no-data" />
        <MgtFileError template="error" />
      </MgtGet>
    </>
  )
}

export default DocumentCreator
