import { PersonGrid } from '@/routes/contacts/person/PersonGrid'
import { defer, LoaderFunction } from 'react-router-typesafe'
import { getAzureUserInformation, useAzureManager } from '@/auth/azure/azureManager'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import PersonCreatorRoute from '@/routes/contacts/person/PersonCreator.route'
import { Roles } from '@/auth/azure/Roles'

export const personGridRouteLoader = (async ({ request, params }: RouteFunctionParams<'clientId'>) => {
  const qsAdminApi = useQsAdminApiManager.getState().service

  const getSites = qsAdminApi.sede().query((builder, qsede) => {
    builder.orderBy(qsede.nome.asc())
    builder.select('nome', 'id')
    builder.filter(params.clientId ? qsede.azienda.props.id.eq(+params.clientId) : null)
  })

  const getRapporti = qsAdminApi.rapporto().query((builder, rapporto) => {
    builder.select('id', 'nome')
    builder.orderBy(rapporto.nome.asc())
  })

  const getAziende = qsAdminApi.azienda().query((builder, azienda) => {
    builder.select('id', 'nome')
    builder.orderBy(azienda.nome.asc())
  })

  return defer({
    sites: await getSites,
    rapporti: await getRapporti,
    aziende: await getAziende,
    userInfo: await getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.ExternalSales,
      Roles.Marketing,
      Roles.TechDeveloper,
    ],
  })
}) satisfies LoaderFunction

export const PersonGridRoute = {
  path: 'people',
  children: [
    {
      index: true,
      element: <PersonGrid />,
      loader: personGridRouteLoader,
      allowedRoles: [Roles.Guest],
    } as RoleRouteObject,
    PersonCreatorRoute,
  ],
  icon: 'user',
  text: 'Persone',
  allowedRoles: [Roles.Guest],
} as RoleRouteObject
