import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import 'devextreme-react/text-area'
import { Map } from 'devextreme-react/map'
import type { Editablesede, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import {
  ButtonItem,
  ButtonOptions,
  EmailRule,
  EmptyItem,
  Form as DxForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { useFetcher } from 'react-router-dom'
import './ClientSite.scss'
import { IClientEditorItemRef } from '@/routes/clients/client/sites/ClientSitesEditor'
import notify from 'devextreme/ui/notify'
import { PopupRef } from 'devextreme-react/cjs/popup'
import FormPopupClientNote from '@/components/form-popup-client-note/FormPopupClientNote'

export type SiteEditorProps = {
  site?: sede | Editablesede
  removeSelf: () => void
  onDirty: () => void
  className?: string
  readOnly?: boolean
  writeInfo?: boolean
}

// eslint-disable-next-line react/display-name
export const ClientSite = forwardRef<IClientEditorItemRef, SiteEditorProps>(
  (props: SiteEditorProps, ref: React.ForwardedRef<IClientEditorItemRef>) => {
    const { site, className, readOnly, writeInfo, removeSelf, onDirty } = props
    const [fullAddress, setFullAddress] = useState<string>('')
    const fetcher = useFetcher()
    const siteId: number | undefined = site ? ('id' in site ? site?.id : undefined) : undefined
    const [formData, setFormData] = useState(site)
    const [centerMapAddress, setCenterMapAddress] = useState<string>(
      site ? `${site?.strada},${site?.citta}, ${site?.provincia}, ${site?.stato ?? 'Italia'}` : 'Parma, IT',
    )
    const formRef = useRef<FormRef>(null)
    const popupNoteClienteRef = useRef<PopupRef>(null)
    const [principale, setPrincipale] = useState<boolean | null | undefined>(site?.principale)

    useEffect(() => {
      const addr = site
        ? `${site?.strada}, ${site?.citta}, ${site?.provincia}, ${site?.stato ?? 'Italia'}`
        : 'Parma, IT'
      setFullAddress(addr)
      setCenterMapAddress(addr)
    }, [site, setFullAddress, setCenterMapAddress])

    useImperativeHandle(ref, () => {
      return {
        validate() {
          console.log('index item validate', site?.nome)
          return formRef.current?.instance().validate()
        },
        submit() {
          try {
            fetcher.submit(JSON.stringify(site), {
              method: siteId ? 'PATCH' : 'POST',
              action: `./${siteId ?? 'new'}`,
              encType: 'application/json',
            })
            return true
          } catch (error) {
            console.error(`Error submitting site data ${site?.nome}`, error)
            notify(`Errore salvataggio dati sede ${site?.nome}. Dettagli : ${error}`, 'error', 3000)
            return false
          }
        },
      } satisfies IClientEditorItemRef
    }, [fetcher, site, siteId])

    useEffect(() => {
      setPrincipale(site?.principale)
      setFormData(site)
    }, [site])

    return (
      <>
        <fetcher.Form className={`${formData?.archived ? 'archived' : ''}`}>
          <DxForm
            className={className}
            id={'azienda_address_form'}
            formData={formData}
            labelLocation={'top'}
            showValidationSummary={true}
            readOnly={readOnly || formData?.archived || false}
            onFieldDataChanged={() => {
              onDirty()
            }}
            ref={formRef}
            colCount={1}
          >
            <GroupItem colCount={4}>
              <SimpleItem dataField="nome" colSpan={2} label={{ text: 'Nome Sede' }}>
                <RequiredRule message="Il nome sede è obbligatorio" />
              </SimpleItem>
              <SimpleItem
                dataField="principale"
                colSpan={1}
                label={{ text: 'Principale' }}
                editorType="dxSwitch"
                editorOptions={{
                  readOnly: readOnly || principale || siteId === undefined,
                }}
              />
              {siteId ? (
                <GroupItem colSpan={1} colCount={3}>
                  <EmptyItem></EmptyItem>
                  <ButtonItem cssClass={'form-top-action-button-info'} name="siteInfo">
                    <ButtonOptions
                      disabled={readOnly && !writeInfo}
                      icon={'info'}
                      hint="Note Cliente"
                      stylingMode={'text'}
                      onClick={(e) => {
                        popupNoteClienteRef.current?.instance().show()
                      }}
                    />
                  </ButtonItem>
                  <ButtonItem cssClass={'form-top-action-button'} name="siteRestore">
                    <ButtonOptions
                      disabled={readOnly || principale === true}
                      icon={formData?.archived ? 'undo' : 'trash'}
                      stylingMode={'text'}
                      type="success"
                      onClick={async (e) => {
                        // In questo modo il form sente direttamente la modifica
                        if (formData) {
                          setFormData({ ...formData, archived: !formData.archived })
                        }
                        // Fondamentale per modificare anche la data source
                        if (site) {
                          site.archived = !site.archived
                        }
                        // In questo caso forzo il dirty sul controllo
                        onDirty()
                      }}
                    />
                  </ButtonItem>
                </GroupItem>
              ) : (
                <ButtonItem cssClass={'form-top-action-button'} name="siteDelete">
                  <ButtonOptions
                    disabled={principale ?? false}
                    icon={'trash'}
                    stylingMode={'text'}
                    type="danger"
                    onClick={(e) => {
                      removeSelf()
                    }}
                  />
                </ButtonItem>
              )}
            </GroupItem>
            <GroupItem caption="Indirizzo" colCount={5}>
              <GroupItem colCount={5} colSpan={5}>
                <SimpleItem dataField="strada" colSpan={2} label={{ text: 'Indirizzo' }}>
                  <RequiredRule message="L'indirizzo è una informazione obbligatoria" />
                </SimpleItem>
                <SimpleItem dataField="citta" colSpan={2} label={{ text: 'Citta' }}>
                  <RequiredRule message="Città è un campo obbligatorio" />
                </SimpleItem>
                <SimpleItem dataField="provincia" colSpan={1} label={{ text: 'Provincia' }} />
                <SimpleItem dataField="cap" label={{ text: 'CAP' }} />
                <SimpleItem dataField="stato" label={{ text: 'Stato' }} />
              </GroupItem>
              <SimpleItem colSpan={5}>
                <Map
                  autoAdjust={true}
                  defaultZoom={15}
                  controls={true}
                  apiKey={{ google: import.meta.env.VITE_GOOGLE_API_KEY }}
                  center={centerMapAddress}
                  markers={[{ location: centerMapAddress }]}
                  provider="google"
                  width={'100%'}
                  height={'25vh'}
                  onReady={(e) => {
                    e.originalMap.gestureHandling = 'cooperative'
                  }}
                />
              </SimpleItem>
            </GroupItem>
            <GroupItem caption="Contatti" colCount={4}>
              <SimpleItem dataField="telefono" colSpan={1} label={{ text: 'Telefono' }}>
                <RequiredRule message="Il numero di telefono è una informazione obbligatoria" />
              </SimpleItem>
              <GroupItem colSpan={3} colCount={2}>
                <SimpleItem colSpan={1} dataField="email" label={{ text: 'Email' }}>
                  <EmailRule message="L'indirizzo email non è valido" ignoreEmptyValue={true} />
                  <RequiredRule message="L'indirizzo email è una informazione obbligatoria" />
                </SimpleItem>
                <SimpleItem colSpan={1} dataField="email_pec" label={{ text: 'PEC' }}>
                  <EmailRule message="L'indirizzo email non è valido" ignoreEmptyValue={true} />
                </SimpleItem>
              </GroupItem>
            </GroupItem>
          </DxForm>
        </fetcher.Form>
        <FormPopupClientNote data={site} refPopup={popupNoteClienteRef}></FormPopupClientNote>
      </>
    )
  },
)
