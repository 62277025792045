import PersonCreatorForm from '@/routes/contacts/person/PersonCreator.form'
import { personCreatorRouteLoader } from '@/routes/contacts/person/PersonCreator.route'
import { Suspense } from 'react'
import { useLoaderData, Await } from 'react-router-typesafe'
import { LoadPanel } from 'devextreme-react/load-panel'
import LoadingPanel from '@/components/loading-panel/LoadingPanel'
import { useFetcher } from 'react-router-dom'

const PersonCreator = () => {
  const { contact, getRapporti, getAziende } = useLoaderData<typeof personCreatorRouteLoader>()
  const fetcher = useFetcher()
  return (
    <Suspense fallback={<LoadingPanel />}>
      <Await resolve={Promise.all([contact, getRapporti, getAziende])}>
        {([contact, rapporti, aziende]) => (
          <>
            <h2>Nuovo Contatto</h2>
            <div id="create-contact-container" className={'dx-card responsive-paddings'}>
              <fetcher.Form>
                <PersonCreatorForm
                  contact={contact}
                  rapporti={rapporti.data.value}
                  aziende={aziende?.data.value}
                  fetcher={fetcher}
                />
              </fetcher.Form>
            </div>
          </>
        )}
      </Await>
    </Suspense>
  )
}

export default PersonCreator
