import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { attivita_lavorativa } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { RoleRouteObject, RouteFunctionParams } from '@/types'
import { redirect } from 'react-router-dom'
import { defer, ActionFunction, LoaderFunction } from 'react-router-typesafe'
import { getAzureUserInformation } from '@/auth/azure/azureManager'
import HourEditor from '@/routes/hours/HourEditor'
import { computeTempo } from '@/routes/hours/utils'
import { Role, Roles } from '@/auth/azure/Roles'

export const hourEditorRouteLoader = (async ({ request, params }: RouteFunctionParams<'hourId' | 'date'>) => {
  const hourId = params.hourId
  const dateSearchParam: string | null = new URL(request.url).searchParams.get('date')
  console.log('dateSearchParam', dateSearchParam)
  let dateSP: Date | null = null
  if (dateSearchParam) {
    const now = new Date()
    dateSP = new Date(dateSearchParam)
    dateSP.setHours(now.getHours())
    dateSP.setMinutes(now.getMinutes())
    dateSP.setSeconds(now.getSeconds())
  }

  const qsAdminApi = useQsAdminApiManager.getState().service
  const userInfo = await getAzureUserInformation()
  userInfo?.email

  let hour: attivita_lavorativa

  const startTime: Date = new Date(0, 0, 0, 0, 0)

  if (hourId) {
    //editor
    const getHour = await qsAdminApi.attivita_lavorativa(Number(hourId)).query((builder, attivita_lavorativa) => {
      builder.expanding('impiegato', (impiegatoBuilder, impiegato) => {
        impiegatoBuilder.select('id', 'fullname', 'reparto', 'email')
        impiegatoBuilder.expanding('reparto', (repartoBuilder, reparto) => {
          repartoBuilder.select('id', 'nome')
        })
      })
      builder.expanding('sede', (sedeBuilder, sede) => {
        sedeBuilder.expanding('azienda', (aziendaSedeBuilder, aziendaSede) => {
          aziendaSedeBuilder.expanding('sedi', (sediAziendaSedeBuilder, sedeAzienda) => {
            sediAziendaSedeBuilder.select('id', 'nome', 'note')
          })
          aziendaSedeBuilder.select('id', 'nome', 'sedi')
        })
        sedeBuilder.select('id', 'nome', 'azienda', 'note')
      })
      builder.expanding('task', (taskBuilder, task) => {
        taskBuilder.select('id', 'ded_Dis')
      })
      builder.expanding('causale', (causaleBuilder, causale) => {
        causaleBuilder.select('id', 'nome', 'note')
      })
      builder.expanding('piattaforma', (piattaformaBuilder, piattaforma) => {
        piattaformaBuilder.select('id', 'nome')
      })
      builder.expanding('centro_costo', (centroCostoBuilder, centroCosto) => {
        centroCostoBuilder.expanding('causali_centro_costo', (causaliBuilder, causale) => {
          causaliBuilder.select('id', 'nome', 'note')
        })
        centroCostoBuilder.select('id', 'nome', 'causali_centro_costo')
      })
    })
    hour = getHour.data satisfies attivita_lavorativa
    if (hour.impiegato?.email !== userInfo?.email) {
      const allowedRoles = [Roles.Administrator, Roles.GlobalAdministrator, Roles.Supervisor]
      const isCurrentUserAllowed = allowedRoles.some((role: Role) => userInfo?.roles?.hasRole(role))
      if (!isCurrentUserAllowed) {
        throw new Error(`L'attività ${hour.ded_Dis} non appartiene all'utente corrente. Impossibile proseguire`)
      }
    }
  } else {
    //creator
    const getAutore = await qsAdminApi.user().query((builder, impiegato) => {
      builder.filter(impiegato.email.eq(userInfo?.email ?? ''))
      builder.expanding('reparto', (repartoBuilder, reparto) => {
        repartoBuilder.select('id', 'nome')
      })
      builder.select('id', 'fullname', 'reparto', 'email')
    })

    hour = {
      id: 0,
      ded_Dis: '',
      ded_RootFam: '',
      ded_Id: 0,
      data: dateSP ? dateSP.toISOString() : new Date().toISOString(),
      tempo_cliente: '',
      tempo_trasferta: '',
      tempo_ufficio: '',
      spese_trasferta: 0,
      spese_vitto: 0,
      spese_alloggio: 0,
      note: null,
      impiegato: getAutore.data.value[0],
      sede: null,
    }
  }
  const getClients = qsAdminApi.azienda().query((builder, azienda) => {
    builder.filter(azienda.sedi.any())
    builder.orderBy(azienda.nome.asc())
    builder.select('id', 'nome')
  })

  const getCentriCosto = qsAdminApi.centro_costo().query((builder, centroCosto) => {
    builder.filter(
      hour.impiegato?.reparto
        ? centroCosto.visibility
            .any((ccVisibility) => ccVisibility.reparto.props.id.eq(Number(hour.impiegato?.reparto?.id)))
            .or(centroCosto.visibility.any((ccVisibility) => ccVisibility.reparto.props.id.eq(null)))
        : null,
    )
    builder.orderBy(centroCosto.nome.asc())
    builder.select('id', 'nome')
  })

  const getPiattaforme = qsAdminApi.piattaforma_attivita_lavorativa().query((builder, piattaforma) => {
    builder.select('id', 'nome')
    builder.orderBy(piattaforma.nome.asc())
  })

  const getTasks = qsAdminApi.task().query((builder, task) => {
    builder.filter(hour.sede ? task.sede.props.id.eq(hour.sede.id) : null)
    builder.select('id', 'ded_Dis')
    builder.orderBy(task.ded_Dis.desc())
  })

  return defer({
    hour,
    tempoUfficio: hourId ? computeTempo(hour.tempo_ufficio) : startTime,
    tempoTrasferta: hourId ? computeTempo(hour.tempo_trasferta) : startTime,
    tempoCliente: hourId ? computeTempo(hour.tempo_cliente) : startTime,
    getClients,
    getCentriCosto,
    getPiattaforme,
    getTasks,
    getUserInfo: getAzureUserInformation(),
    defaultCRUDAllowedRoles: [
      Roles.GlobalAdministrator,
      Roles.Administrator,
      Roles.Supervisor,
      Roles.Sales,
      Roles.Marketing,
      Roles.TechDeveloper,
    ],
  })
}) satisfies LoaderFunction

export const hourEditorRouteAction = (async ({ request }: RouteFunctionParams) => {
  const hour = (await request.json()) as attivita_lavorativa
  console.log('hour', hour)
  const qsAdminApi = useQsAdminApiManager.getState().service

  switch (request.method) {
    case 'POST': {
      const res = await qsAdminApi.attivita_lavorativa().create(hour)
      console.log(res)
      return redirect(`/hours/new`)
    }
    case 'PUT':
    case 'PATCH': {
      return await qsAdminApi.attivita_lavorativa(hour.id).update(hour)
    }
    default: {
      throw new Response('Method not allowed', {
        status: 405,
        statusText: 'Method not allowed',
      })
    }
  }
}) satisfies ActionFunction

const HourEditorRoute = {
  path: ':hourId',
  element: <HourEditor creating={false} />,
  loader: hourEditorRouteLoader,
  action: hourEditorRouteAction,
  allowedRoles: [Roles.Guest],
} as RoleRouteObject

export default HourEditorRoute
