import { ArraySourceComposer } from '@/auth/api/connector'
import DateColumn from '@/components/date-column/DateColumn'
import { GridCellColor } from '@/enums'
import { contatto_aziendale, entita_aziendale, offerta } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { DataGrid } from 'devextreme-react'
import { Column, Paging, Button as GridButton } from 'devextreme-react/cjs/data-grid'
import { CellPreparedEvent, ColumnButtonClickEvent, RowDblClickEvent } from 'devextreme/ui/data_grid'
import { useNavigate } from 'react-router-dom'

const ProjectEditorFormOffersGrid = (props: { offers?: offerta[] }) => {
  const { offers } = props

  const onCellPrepared = (e: CellPreparedEvent) => {
    if (e.rowType === 'data' && e.column.dataField === 'stato.nome' && e.value) {
      switch (e.value) {
        case 'PERSA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.SALMON}`

          break
        }
        case 'APERTA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.YELLOW}`

          break
        }
        case 'ORDINATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.LILAC}`

          break
        }
        case 'SUPERATA': {
          e.cellElement.style.cssText = `${e.cellElement.style.cssText}color: black; background-color: ${GridCellColor.ORANGE}`

          break
        }
      }
    }
  }

  const calculateRiferimentiValue = (rowData: offerta) => {
    let riferimenti: string = ''
    rowData.riferimenti?.map(
      (contatto: contatto_aziendale) => (riferimenti = `${riferimenti + contatto.fullname?.toUpperCase()} `),
    )
    rowData.riferimenti_entita?.map(
      (contatto: entita_aziendale) => (riferimenti = `${riferimenti + contatto.nome?.toUpperCase()} `),
    )
    return riferimenti.trim()
  }

  return (
    <>
      <DataGrid
        id={`project-offers-grid`}
        dataSource={ArraySourceComposer(
          'id',
          offers
            ? offers.sort((offerA: offerta, offerB: offerta) => {
                const dataA: Date = new Date(offerA.data_creazione ?? '')
                const dataB: Date = new Date(offerB.data_creazione ?? '')
                return dataB.getTime() - dataA.getTime()
              })
            : [],
        )}
        noDataText="Nessun' offerta associata alla commessa"
        keyExpr={'id'}
        showBorders={true}
        showColumnLines={true}
        focusedRowEnabled={true}
        columnHidingEnabled={false}
        allowColumnResizing={true}
        rowAlternationEnabled={true}
        wordWrapEnabled={false}
        repaintChangesOnly={true}
        width="100%"
        onCellPrepared={onCellPrepared}
        onRowDblClick={(e: RowDblClickEvent<any, any>) => {
          if (e.rowType === 'data') window.open(`/offers/${e.data.id}`, '_blank')
        }}
      >
        <Paging defaultPageSize={8} />
        <Column type="buttons" width={'4%'} alignment="left">
          <GridButton
            hint="Details"
            icon="search"
            onClick={(e: ColumnButtonClickEvent) => {
              window.open(`/offers/${e.row?.data.id}`, '_blank')
            }}
          />
        </Column>
        <Column dataField={'id'} caption={'ID'} visible={false} />,
        <Column dataField={'ded_Dis'} caption={'CODICE'} />,
        <Column dataField={'revisione'} caption={'REV'} />,
        <Column dataField={'autore.fullname'} caption={'AUTORE'} />
        <Column name={'info_riferimenti'} caption={'RIFERIMENTI'} calculateCellValue={calculateRiferimentiValue} />
        {DateColumn({ dataField: 'data_creazione', caption: 'DATA', format: 'dd/MM/yyyy' })}
        <Column dataField={'note'} caption={'NOTE'} />
        <Column dataField={'stato.nome'} caption={'STATO'} />
      </DataGrid>
    </>
  )
}

export default ProjectEditorFormOffersGrid
