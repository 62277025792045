import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { Editablesede, sede } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { toolbarHtmlEditor } from '@/routes/utils'
import { useScreenSize } from '@/themes/media-query'
import { Popup } from 'devextreme-react'
import { PopupRef } from 'devextreme-react/cjs/popup'
import { ButtonItem, ButtonOptions, GroupItem, SimpleItem, Form as DXForm, FormRef } from 'devextreme-react/form'
import { ClickEvent } from 'devextreme/ui/button'
import React, { useRef } from 'react'

const FormPopupClientNote = (props: {
  data: Editablesede | sede | null | undefined
  refPopup: React.RefObject<PopupRef>
  refreshDataSourceSites?: () => void
}) => {
  const currentScreenSize = useScreenSize()
  const { data, refPopup, refreshDataSourceSites } = props
  const { service } = useQsAdminApiManager()
  const formRef = useRef<FormRef>(null)
  const objSiteClientNote: {
    id: number | undefined
    nome: string | null | undefined
    note: string | null | undefined
  } = { id: data && 'id' in data ? data?.id : 0, nome: data?.nome, note: data?.note }
  return (
    <>
      <Popup
        ref={refPopup}
        id="sede_note_cliente_popup"
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={true}
        showTitle={true}
        title={`Note Cliente Sede: ${objSiteClientNote?.nome}`}
        container=".dx-viewport"
        resizeEnabled={true}
        width={800}
        height={550}
      >
        <DXForm
          id={'sede_note_cliente_form'}
          formData={objSiteClientNote}
          ref={formRef}
          labelLocation={'top'}
          colCount={1}
          showValidationSummary={true}
          validationGroup="NoteClienteValidationGroup"
        >
          <GroupItem colCount={1}>
            <SimpleItem
              colSpan={1}
              dataField="note"
              label={{ text: 'Note Cliente' }}
              editorType={'dxHtmlEditor'}
              editorOptions={{
                stylingMode: 'filled',
                valueType: 'html',
                toolbar: toolbarHtmlEditor(currentScreenSize),
                mediaResizing: {
                  enabled: true,
                },
                imageUpload: {
                  tabs: ['file', 'url'],
                  fileUploadMode: 'base64',
                },
                height: '300',
              }}
            ></SimpleItem>
          </GroupItem>
          <GroupItem cssClass="last-group">
            <GroupItem cssClass="buttons-group" colCount={1}>
              <ButtonItem name="btn-save">
                <ButtonOptions
                  icon="save"
                  text="Salva"
                  width="150px"
                  onClick={async (e: ClickEvent) => {
                    const validationResult = formRef.current?.instance().validate()
                    if (!validationResult?.isValid) return
                    console.log('validation ok', objSiteClientNote)
                    await service.sede(Number(objSiteClientNote?.id)).patch(objSiteClientNote)
                    if (refreshDataSourceSites) refreshDataSourceSites()
                    refPopup.current?.instance().hide()
                  }}
                />
              </ButtonItem>
            </GroupItem>
          </GroupItem>
        </DXForm>
      </Popup>
    </>
  )
}

export default FormPopupClientNote
