import { SingleCard } from '../../../layouts'
import { CreateAccountForm } from '../../../components'
import type { RouteObject } from 'react-router-dom'

const CreateRoute: RouteObject = {
  path: 'signup',
  element: (
    <SingleCard title="Sign Up">
      <CreateAccountForm />
    </SingleCard>
  ),
}

export default CreateRoute
