import './NoteEditor.scss'
import {
  AdmonitionDirectiveDescriptor,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CodeToggle,
  CreateLink,
  diffSourcePlugin,
  DiffSourceToggleWrapper,
  directivesPlugin,
  headingsPlugin,
  imagePlugin,
  InsertImage,
  InsertThematicBreak,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo,
} from '@mdxeditor/editor'
import React from 'react'
import { DXFormSimpleItemRenderItem } from '@/types'
import { ITextBoxOptions } from 'devextreme-react/text-box'
import { ISimpleItemProps, SimpleItem } from 'devextreme-react/cjs/form'

type NoteEditorProps<T> = {
  item: DXFormSimpleItemRenderItem<T>
  linkAutocompleteSuggestions?: string[]
}
// eslint-disable-next-line no-secrets/no-secrets
// & NoteEditorProps<ITextBoxOptions>['linkAutocompleteSuggestions']
export const NoteEditorItem = (props: ISimpleItemProps) => {
  return (
    <SimpleItem
      {...props}
      label={{ visible: false }}
      editorType={'dxTextBox'}
      render={(item: DXFormSimpleItemRenderItem<ITextBoxOptions>) => {
        console.log('rendering NoteEditorItem')
        return <NoteEditor item={item} />
      }}
    />
  )
}
export const NoteEditor = ({ item, linkAutocompleteSuggestions = [] }: NoteEditorProps<ITextBoxOptions>) => {
  const mdxEditorRef = React.useRef<MDXEditorMethods>(null)
  return (
    <MDXEditor
      // className={'dark-theme dark-editor'}
      onChange={(markdown: string) => {
        item.component.option('formData')[item.dataField as string] = markdown
      }}
      ref={mdxEditorRef}
      markdown={item.component.option('formData')[item.dataField as string] || ''}
      readOnly={item.editorOptions.readOnly}
      plugins={[
        headingsPlugin(),
        listsPlugin(),
        directivesPlugin({ directiveDescriptors: [AdmonitionDirectiveDescriptor] }),
        thematicBreakPlugin(),
        diffSourcePlugin(),
        imagePlugin(),
        quotePlugin(),
        markdownShortcutPlugin(),
        tablePlugin(),
        thematicBreakPlugin(),
        linkPlugin(),
        linkDialogPlugin({
          linkAutocompleteSuggestions,
        }),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <DiffSourceToggleWrapper>
                <UndoRedo />
                <BoldItalicUnderlineToggles />
                <ListsToggle />
                <CodeToggle />
                <CreateLink />
                <InsertImage />
                <InsertThematicBreak />
                <BlockTypeSelect />
              </DiffSourceToggleWrapper>
            </>
          ),
        }),
      ]}
    />
  )
}
