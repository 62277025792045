import { ArraySourceComposer } from '@/auth/api/connector'
import { useQsAdminApiManager } from '@/auth/api/qsadminapiManager'
import { ad_product_catalog, ad_promotion, ad_subscription } from '@/model/qsadminapi/QsAdminApiModuleModel'
import { ILineItemRef, LineItemProps } from '@/routes/quotes/line-items/LineItem.types'
import {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Form as DXForm,
  FormRef,
  GroupItem,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form'
import { ValidationCallbackData } from 'devextreme/common'
import DataSource from 'devextreme/data/data_source'
import { CustomItemCreatingEvent, ValueChangedEvent as ValueChangedEventSelectBox } from 'devextreme/ui/select_box'
import { ValueChangedEvent } from 'devextreme/ui/lookup'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { NumberBoxTypes } from 'devextreme-react/cjs/number-box'
import './LineItem.scss'
import PricingGroupItem from '@/routes/quotes/line-items/PricingGroupItem'

// eslint-disable-next-line react/display-name
const LineItemCoterm = forwardRef<ILineItemRef, LineItemProps>(
  (props: LineItemProps, ref: React.ForwardedRef<ILineItemRef>) => {
    const { item, products, csn, creating, readOnly, removeSelf } = props

    const title = item.quoteLineNumber ? `${item.action} ${item.quoteLineNumber}` : `${item.action}`

    const { service } = useQsAdminApiManager()

    const formRef = useRef<FormRef>(null)

    const [subscriptionsDataSource, setSubscriptionsDataSource] = useState<ad_subscription[]>([])
    const [currentSubs, setCurrentSubs] = useState<string | ad_subscription | null | undefined>(item.subscription)
    const [style, setStyle] = useState<string>('line-item')
    const [termDataSource, setTermDataSource] = useState<DataSource>(
      ArraySourceComposer(
        'termCode',
        item && 'offer' in item && item.offer && item.offer.term ? [item.offer.term] : [],
      ),
    )
    const [promotionDataSource, setPromotionDataSource] = useState<DataSource>(
      ArraySourceComposer(
        'code',
        item && 'promotions' in item && item.promotions
          ? [...new Map(item.promotions.map((promotion: ad_promotion) => [promotion['code'], promotion])).values()]
          : [],
      ),
    )

    const getTerms = async (productCode: string) => {
      const termsData = await service.ad_product_catalog().query((builder, product) => {
        builder.filter(product.offeringCode.eq(productCode))
        builder.select('termCode', 'termDescription')
        builder.orderBy(product.termCode.asc())
      })
      return termsData.data.value
    }

    const getPromotions = async (
      action: string | undefined,
      productCode: string | null | undefined,
      iuCode: string | undefined,
      termCode: string | undefined | null,
      amCode: string | undefined | null,
      quantity: number | undefined,
    ) => {
      const promotionsData = await service.ad_promotion().query((builder, promotion) => {
        builder.filter(promotion.offeringCode.eq(productCode ?? null))
        builder.filter(promotion.orderAction.eq(action ?? null))
        builder.filter(promotion.startDate.lowerEquals(new Date().toISOString()))
        builder.filter(promotion.endDate.greaterEquals(new Date().toISOString()).or(promotion.endDate.eq(null)))
        builder.filter(promotion.intendedUsageCode.eq(iuCode ?? null).or(promotion.intendedUsageCode.eq(null)))
        builder.filter(promotion.termCode.eq(termCode ?? null).or(promotion.termCode.eq(null)))
        builder.filter(promotion.accessModelCode.eq(amCode ?? null).or(promotion.accessModelCode.eq(null)))
        builder.filter(promotion.quantityNeeded.lowerEquals(quantity ?? 0))
        builder.orderBy(promotion.code.asc())
      })
      return promotionsData.data.value
    }

    const getSubscriptions = async (csnId: number) => {
      const subscriptionsData = await service.ad_subscription().query((builder, subscription) => {
        builder.filter(subscription.csn.props.id.eq(csnId))
        builder.filter(subscription.status.eq('Active'))
        builder.select('id', 'subscriptionId', 'serialNumber', 'quantity', 'productCode', 'productName', 'term')
      })
      return subscriptionsData.data.value
    }

    //metodi esposti dal componente
    useImperativeHandle(ref, () => {
      return {
        validate() {
          console.log('index item validate', item.lineNumber)
          return formRef.current?.instance().validate()
        },
      } satisfies ILineItemRef
    }, [item.lineNumber])

    //al primo render del componente carico le subs
    useEffect(() => {
      getSubscriptions(Number(csn?.id)).then((subs: ad_subscription[]) => {
        setSubscriptionsDataSource(subs)
      })
    }, [])

    return (
      <>
        <div id={`item-quote-container-${item.lineNumber}`} className={style}>
          <DXForm
            id={`item_form_${item.lineNumber}`}
            key={`item_form_${item.lineNumber}`}
            formData={item}
            labelLocation={'top'}
            colCount={1}
            showValidationSummary={true}
            validationGroup={`itemValidation_${item.lineNumber}`}
            ref={formRef}
            readOnly={readOnly}
          >
            <GroupItem colCount={2}>
              <SimpleItem>
                <h3 className="title">{title}</h3>
              </SimpleItem>
              {item.operation === '' || item.operation === 'Insert' ? (
                <ButtonItem>
                  <ButtonOptions
                    disabled={readOnly}
                    text="Elimina"
                    icon={'trash'}
                    stylingMode={'text'}
                    type="danger"
                    onClick={(e) => {
                      removeSelf()
                      setStyle('line-item deleted')
                    }}
                  />
                </ButtonItem>
              ) : (
                <ButtonItem>
                  <ButtonOptions
                    disabled={readOnly}
                    text="Ripristina"
                    icon={'undo'}
                    stylingMode={'text'}
                    type="success"
                    onClick={(e) => {
                      item.operation = ''
                      setStyle('line-item')
                    }}
                  />
                </ButtonItem>
              )}
            </GroupItem>
            <GroupItem colCount={5}>
              <SimpleItem
                dataField="subscription"
                label={{ text: 'Subscription' }}
                editorType="dxSelectBox"
                editorOptions={{
                  value: currentSubs,
                  dataSource: ArraySourceComposer('id', subscriptionsDataSource),
                  placeholder: 'Selezionare subscription...',
                  displayExpr: 'subscriptionId',
                  searchEnabled: true,
                  acceptCustomValue: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare subscription',
                    hideOnOutsideClick: true,
                  },
                  readOnly: item.quoteLineNumber || readOnly ? true : false,
                  disabled: item.operation === 'Delete',
                  onCustomItemCreating: (e: CustomItemCreatingEvent) => {
                    console.log('CustomItemCreatingEvent', e)
                    if (!e.customItem) e.customItem = e.text
                    setCurrentSubs(e.customItem)
                  },
                  onValueChanged: (e: ValueChangedEventSelectBox) => {
                    console.log('ValueChangedEventSelectBox', e)
                    if (e.previousValue?.id === e.value?.id) return
                    setCurrentSubs(e.value)
                  },
                }}
              >
                <RequiredRule message="Subscription obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="productCatalog"
                label={{ text: 'Prodotto' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: ArraySourceComposer('offeringId', products),
                  placeholder: 'Selezionare il prodotto...',
                  displayExpr: 'offeringName',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il prodotto',
                    hideOnOutsideClick: true,
                  },
                  onValueChanged: (e: ValueChangedEvent) => {
                    if (e.previousValue?.offeringCode === e.value?.offeringCode) return
                    console.log('PRODUCT EVENT CHANGED', e.previousValue, e.value)
                    if (e.value) {
                      getTerms(e.value.offeringCode).then((res: ad_product_catalog[]) => {
                        setTermDataSource(
                          ArraySourceComposer('termCode', [
                            ...new Map(
                              res.map((product: ad_product_catalog) => [product['termCode'], product]),
                            ).values(),
                          ]),
                        )
                        formRef.current?.instance().updateData('offer.term', null)
                      })
                    }
                  },
                  readOnly: item.quoteLineNumber || readOnly ? true : false,
                  disabled: item.operation === 'Delete',
                }}
              >
                <RequiredRule message="Prodotto obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="offer.term"
                label={{ text: 'Termini' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: termDataSource,
                  placeholder: 'Selezionare il termine...',
                  displayExpr: 'termDescription',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare il termine',
                    hideOnOutsideClick: true,
                  },
                  readOnly: item.quoteLineNumber || readOnly ? true : false,
                  disabled: item.operation === 'Delete',
                }}
              >
                <RequiredRule message="Termini obbligatorio"></RequiredRule>
              </SimpleItem>
              <SimpleItem
                dataField="startDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  disabled: item.operation === 'Delete',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Inizio' }}
              >
                <RequiredRule message="Data Inizio obbligatorio"></RequiredRule>
                <CustomRule
                  message="La data di inizio deve essere entro 30 giorni dalla data odierna"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    if (value) {
                      const dataInizio: Date = value
                      const today: Date = new Date()
                      dataInizio.setHours(today.getHours())
                      dataInizio.setMinutes(today.getMinutes())
                      dataInizio.setSeconds(today.getSeconds())
                      const tsDifference: number = dataInizio.getTime() - today.getTime()
                      const dayDifference: number = Math.floor(tsDifference / (1000 * 60 * 60 * 24))
                      return dayDifference < 29
                    } else return true
                  }}
                ></CustomRule>
              </SimpleItem>
              <SimpleItem
                dataField="endDate"
                editorOptions={{
                  displayFormat: 'dd/MM/yyyy',
                  disabled: item.operation === 'Delete',
                }}
                editorType="dxDateBox"
                label={{ text: 'Data Fine' }}
              ></SimpleItem>
              <SimpleItem
                dataField="quantity"
                editorType="dxNumberBox"
                label={{ text: 'Quantità' }}
                editorOptions={{
                  disabled: item.operation === 'Delete',
                  showSpinButtons: true,
                  min: 0,
                  onValueChanged: (e: NumberBoxTypes.ValueChangedEvent) => {
                    console.log('quantity event changed', e)
                    getPromotions(
                      item.action,
                      item.productCatalog?.offeringCode,
                      'COM',
                      item.offer?.term?.termCode,
                      item.productCatalog?.accessModelCode,
                      item.quantity,
                    ).then((res: ad_promotion[]) => {
                      setPromotionDataSource(
                        ArraySourceComposer('code', [
                          ...new Map(res.map((promotion: ad_promotion) => [promotion['code'], promotion])).values(),
                        ]),
                      )
                    })
                  },
                }}
              >
                <RequiredRule message="Quantità obbligatoria"></RequiredRule>
                <CustomRule
                  message="Quantità deve essere maggiore di 0"
                  validationCallback={({ value }: ValidationCallbackData) => {
                    return value > 0
                  }}
                ></CustomRule>
              </SimpleItem>
              <SimpleItem
                dataField="promotion"
                label={{ text: 'Promozione' }}
                editorType="dxLookup"
                editorOptions={{
                  dataSource: promotionDataSource,
                  placeholder: 'Selezionare la promozione...',
                  displayExpr: 'code',
                  searchEnabled: true,
                  dropDownCentered: true,
                  dropDownOptions: {
                    showTitle: true,
                    title: 'Selezionare la promozione',
                    hideOnOutsideClick: true,
                  },
                  clearButtonText: 'Clear',
                  showClearButton: true,
                  disabled: item.operation === 'Delete',
                }}
              ></SimpleItem>
            </GroupItem>
            {!creating && item.operation !== 'Insert' && PricingGroupItem()}
          </DXForm>
        </div>
      </>
    )
  },
)

export default LineItemCoterm
