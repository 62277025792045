import { Roles } from '@/auth/azure/Roles'
import RouterErrorHandler from '@/components/RouterErrorHandler'
import PackagesPage from '@/routes/packages/PackagesPage'
import { PackagesSaleGridRoute } from '@/routes/packages/sale/PackagesSaleGrid.route'
import { PackagesUsageGridRoute } from '@/routes/packages/usage/PackagesUsageGrid.route'
import { RoleRoute } from '@/types'
import { Navigate } from 'react-router-dom'

export const packagesSubRoutes: RoleRoute[] = [
  {
    index: true,
    element: <Navigate to={'sale'} replace={true} />,
    visible: false,
    allowedRoles: [Roles.Guest],
  },
  PackagesSaleGridRoute,
  PackagesUsageGridRoute,
]

export const PackagesPageRoute: RoleRoute = {
  path: 'packages',
  element: <PackagesPage />,
  errorElement: <RouterErrorHandler />,
  children: packagesSubRoutes,
  text: 'Pacchetti',
  icon: 'box',
  mainMenuRoute: true,
  allowedRoles: [Roles.Guest],
}
